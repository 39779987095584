import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DarkModeToggle = _resolveComponent("DarkModeToggle")!
  const _component_NavbarNavigation = _resolveComponent("NavbarNavigation")!
  const _component_SidebarComponent = _resolveComponent("SidebarComponent")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MiniReproductor = _resolveComponent("MiniReproductor")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DarkModeToggle),
    (!_ctx.$route.meta.hidenav)
      ? (_openBlock(), _createBlock(_component_NavbarNavigation, { key: 0 }))
      : _createCommentVNode("", true),
    (!_ctx.$route.meta.hidenav)
      ? (_openBlock(), _createBlock(_component_SidebarComponent, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view),
    (!_ctx.$route.meta.hidemp3)
      ? (_openBlock(), _createBlock(_component_MiniReproductor, { key: 2 }))
      : _createCommentVNode("", true)
  ], 64))
}