
  import { defineComponent, computed, ref, onMounted, watch } from 'vue';
  import { useStore } from 'vuex';
  import { Playlist } from '../../../store/types/types';
  
  export default defineComponent({
    setup() {
      const store = useStore();
      const playlistsToShow = ref(5);
      const isPlaying = computed(() => store.state.player.playing);
      const currentPlaylist = computed(() => store.state.player.currentPlaylist);
      const favoritePlaylists = computed(() => store.state.favoritos.favoritePlaylists);
  
    
  
      const showMorePlaylists = () => {
        playlistsToShow.value += 5;
      };

      const redirectToPlaylist = (playlistId: string) => {
        const baseUrl = process.env.NODE_ENV === 'production' ? 'https://wavesmusic.app' : 'http://localhost:8080';
        window.location.href = `${baseUrl}/playlist/${playlistId}`;
      };
      watch(favoritePlaylists, (newVal, oldVal) => {
      console.log('favoritePlaylists changed:', newVal);
      }, { deep: true });

    // Modifica la función de playPlaylist para añadir depuración
    const playPlaylist = (playlist: Playlist) => {
      // Depuración: Asegurarse de que el objeto playlist y la propiedad id existan
      if (!playlist || !playlist.Id) {
        console.error('Playlist or playlist.Id is undefined', playlist);
        return; // Detener la función si no hay datos válidos
      }
      
      if (currentPlaylist.value === playlist.Id) {
        store.dispatch('togglePlay');
      } else {
        store.dispatch('playPlaylist', playlist);
      }
    };



      const groupedPlaylists = computed(() => {
        let groups: Playlist[][] = [];
        let totalGroupsToShow = Math.ceil(playlistsToShow.value / 5);
        console.log("Fav Playlists", favoritePlaylists.value);
        for (let i = 0; i < totalGroupsToShow; i++) {
          let start = i * 5;
          let end = start + 5;
          const slice = favoritePlaylists.value.slice(start, end);
          console.log(`Grupo ${i}:`, slice);

          groups.push(slice);
        }
  
        return groups;
      });
      
      watch(groupedPlaylists, (newGroups) => {
      console.log('groupedPlaylists:', newGroups);
    }, { deep: true });
      onMounted(() => {
        const userString = localStorage.getItem('user');
        const userObject = JSON.parse(userString || '{}');
        const userId = userObject.id;
  
        if (userId) {
          store.dispatch('fetchFavoritePlaylists', userId);
        }
      });
  
      return {
          isPlaying,
          currentPlaylist,
          playPlaylist,
          showMorePlaylists,
          groupedPlaylists,
          redirectToPlaylist
      };
    }
  });
  