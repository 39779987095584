
import { ref, onMounted, onUnmounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import SearchbarNavigation from "@/components/Searchbar/SearchbarNavigation.vue";

export default {
  components: {
    SearchbarNavigation,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    

    // Definición de datos reactivos
    const isResponsive = ref(false);
    const showDropdown = ref(false);

    const fetchUser = async (id: number | null) => {
      if (id === null) {
        router.push("/login");
        return;
      }
      await store.dispatch("fetchUser", id);
    };
    const goBack = () => {
      router.go(-1); // Navega a la página anterior
    };

    const goForward = () => {
      router.go(1); // Navega a la página siguiente
    };
    onMounted(async () => {
      if (window.innerWidth <= 767) {
        isResponsive.value = true;
      }
      window.addEventListener("resize", handleResize);

      const storedUserItem = localStorage.getItem("user");
      if (storedUserItem !== null) {
        const storedUser = JSON.parse(storedUserItem);
        if (storedUser && storedUser.id) {
          await fetchUser(storedUser.id);
        } else {
          console.error("No se pudo obtener el ID del usuario");
        }
      } else {
        console.error(
          "No hay información de usuario en localStorage, redireccionando a /login"
        );
        router.push("/login");
      }
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    // Métodos
    const toggleDropdown = () => {
      showDropdown.value = !showDropdown.value;
    };

    const logout = () => {
      localStorage.clear();
      router.push("/login");
    };

    const handleResize = () => {
      isResponsive.value = window.innerWidth <= 767;
    };

    return {
      user: computed(() => store.state.user.user),
      isResponsive,
      showDropdown,
      toggleDropdown,
      logout,
      goBack,
      goForward
    };
  },
};
