import axios from 'axios';
import { MutationTree, ActionTree } from 'vuex';
import { RootState } from '../types/types'; 

export interface FrequenciesState {
  frequencies: number[];
  fetchedSongs: Set<number>;  
}

const state: FrequenciesState = {
  frequencies: [],
  fetchedSongs: new Set<number>(),  
};

const mutations: MutationTree<FrequenciesState> = {
  setFrequencies(state, frequencies: number[]) {
    state.frequencies = frequencies;
  },
  addFetchedSong(state, songId: number) {
    state.fetchedSongs.add(songId); 
  }
};

const actions: ActionTree<FrequenciesState, RootState> = {
  async fetchFrequencies({ commit, state }, songsId: number) {
    console.log("Entrando a fetchFrequencies");
    console.log("songsId: ", songsId);

    // Verificar si ya se ha obtenido la canción
    if (state.fetchedSongs.has(songsId)) {
      console.log("Las frecuencias para esta canción ya fueron obtenidas");
      return;
    }

    let usuarioId;
    try {
        const userObject = JSON.parse(localStorage.getItem('user') || '{}');
        usuarioId = userObject.id;

        if (!usuarioId) {
            console.error("Usuario o su ID no encontrado en localStorage");
            return;
        }
    } catch (error) {
        console.error('Error al recuperar el usuario desde localStorage:', error);
        return;
    }
    console.log("Usuario ID: ", usuarioId);

    const payload = {
      usuarioId,
      songsId,
    };

    try {
      const response = await axios.post('https://wavesmusic.app/api/frecuencias/frecuencias', payload);

      console.log('Frecuencias obtenidas:', response.data.frecuencias);
      
      commit('setFrequencies', response.data.frecuencias);
      
      // Agregar el ID de la canción al Set después de una llamada exitosa
      commit('addFetchedSong', songsId);
    } catch (error) {
      console.error("Error al obtener las frecuencias:", error);
    }
    console.log("Finalizando fetchFrequencies");
  },
};

export default {
  state,
  mutations,
  actions,
};
