import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import LoginView from "../views/auth/LoginView.vue";
import RegisterView from "@/views/auth/RegisterView.vue";
import ForgotView from "@/views/auth/ForgotView.vue";
import HomeView from "@/views/Home/HomeView.vue";
import TrendingView from "@/views/Trending/TrendingView.vue";
import ExploreView from "@/views/Explore/ExploreView.vue";
import SettingsView from "@/views/Settings/SettingsView.vue";
import MyCollectionView from "@/views/myCollection/myCollectionView.vue";
import AlbumsCollectionView from "@/views/myCollection/Albums/AlbumsView.vue";
import ArtistsCollectionView from "@/views/myCollection/Artists/ArtistsView.vue";
import MixesCollectionView from "@/views/myCollection/Mixes/MixesView.vue";
import SongsCollectionView from "@/views/myCollection/Songs/SongsView.vue";
import RecentCollectionView from "@/views/myCollection/Recent/RecentView.vue";
import MyPlaylistView from "@/views/myPlaylist/myPlaylistView.vue";
import PlaylistView from "@/views/Playlist/PlaylistView.vue";
import axios from "axios";
const routes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/',
    redirect: '/myCollection/mixes'
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
  {
    path: '/login',
    name: 'login',
    component: LoginView,

    meta: {
      hidemp3: true,
      hidenav: true 
    }
  },
  {
    path: '/playlist/:id',
    name: 'playlist',
    component: PlaylistView,
  },
  {
    path: '/signup',
    name: 'signup',
    component: RegisterView,
    meta: {
      hidemp3: true, 
      hidenav: true
    }
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: ForgotView,
    meta: {
      hidemp3: true,
      hidenav: true 
    }
  },
  {
    path: '/artist/:id',
    name: 'artistProfile',
    component: () => import('../views/Artists/ArtistProfile.vue'),
    
  },
  {
    path: "/user/:id",
    name: "userProfile",
    component: () => import("../views/Users/UserProfileId.vue"),
  },
  {
    path: "/profile",
    name: "UserSelf",
    component: () => import("../views/Users/UserProfile.vue"),
  },
  {
    path: '/trending',
    name: 'trending',
    component: TrendingView,
  },
  {
    path: '/explore',
    name: 'explore',
    component: ExploreView,
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView,
  },
  {
    path: '/myCollection',
    name: 'myCollection',
    component: MyCollectionView,
  },
  {
    path: '/myCollection/albums',
    name: 'myCollection/albums',
    component: AlbumsCollectionView,
  },
  {
    path: '/myCollection/artists',
    name: 'myCollection/artists',
    component: ArtistsCollectionView,
  },
  {
    path: '/myCollection/mixes',
    name: 'myCollection/mixes',
    component: MixesCollectionView,
  },
  {
    path: '/myCollection/songs',
    name: 'myCollection/songs',
    component: SongsCollectionView,
  },
  {
    path: '/myCollection/recent',
    name: 'myCollection/recent',
    component: RecentCollectionView,
  },
  {
    path: '/myCollection/myPlaylist',
    name: 'myCollection/myPlaylist',
    component: MyPlaylistView,
  },
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

async function isAuthenticated() {
  const userDataString = localStorage.getItem("user");
  console.log(userDataString);
  if (userDataString) {
    const user = JSON.parse(userDataString);
    console.log(user); // Para depurar
    const token = localStorage.getItem("userToken");
    console.log("Token: ", token); // Para depurar
    if (token) {
      // Asegúrate de que este await está dentro de un try/catch
      try {
        const response = await axios.post(
          "https://wavesmusic.app/api/verifyToken/",
          { token: token }
        );
        console.log("Response status: ", response.status); // Para depurar
        if (response.status === 200) {
          return true;
        }
      } catch (error) {
        console.error("Error: ", error);
      }
    }
  }
  return false;
}

router.beforeEach(async (to, from, next) => {
  const isAuth = await isAuthenticated();
  console.log("Is authenticated: ", isAuth); // Ya tienes este log
  if (to.name !== "login" && !isAuth) {
    console.log("Redirigiendo a login porque el usuario no está autenticado."); // Agregar este log
    next({ name: "login" });
  } else {
    console.log("Acceso permitido a la ruta."); // Agregar este log
    next();
  }
});

export default router