
import { toRefs, ref, watch, onMounted, computed, onUnmounted } from "vue";
import { useStore } from "vuex";
import ReproductorGrande from "./ReproductorGrande.vue";
import MiniReproductorMobile from "./MiniReproductorMobile.vue";
import { Song } from "../../store/types/types";

export default {
  components: {
    ReproductorGrande,
    MiniReproductorMobile,
  },
  setup() {
    const store = useStore();
    const isDesktop = ref(window.matchMedia("(min-width: 768px)").matches);
    const handleResize = () => {
      isDesktop.value = window.matchMedia("(min-width: 768px)").matches;
    };
    const mute = computed(() => store.state.player.mute);

    const fetchFrequenciesFromStore = (songsId: number) => {
      store.dispatch("fetchFrequencies", songsId);
    };

    const fetchedSongs = computed(() => store.state.frecuencias.fetchedSongs);

    const song = ref<Song | null>(null);

    const { audio, playing } = toRefs(store.state.player);
    const currentTime = computed(() => store.state.player.currentTime);
    const totalTime = computed(() => store.state.player.totalTime);

    const prevVolume = ref<number>(100);
    const isExpanded = ref<boolean>(false);
    const expandScreen = () => {
      isExpanded.value = !isExpanded.value;
    };
    const openReproductorGrande = () => {
      isExpanded.value = !isExpanded.value;
    };
    const closeScreen = () => {
      isExpanded.value = false;
    };
    const shuffle = computed(() => store.state.player.shuffle);
    const repeat = computed(() => store.state.player.repeat);
    const volume = computed({
      get: () => store.state.player.volume,
      set: (volume: number) => {
        prevVolume.value = store.state.player.volume;
        store.dispatch("setVolume", volume);
        store.dispatch("setMute", volume === 0);
        if (audio.value) {
          audio.value.volume = volume / 100;
        }
      },
    });
    const handleSeek = (time: number) => {
      if (audio.value) {
        audio.value.currentTime = time;
        store.dispatch("setCurrentTime", time);
      }
    };

    const handleVolumeChange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      const volumeValue = parseInt(target.value);
      volume.value = volumeValue;
    };

    const formatTime = (time: number) => {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    };
    const seek = (event: MouseEvent) => {
      if (audio.value) {
        const progressBar = event.currentTarget as HTMLDivElement;
        const rect = progressBar.getBoundingClientRect();
        const clickX = event.clientX - rect.left;
        const barWidth = rect.width;
        const seekTime = (clickX / barWidth) * totalTime.value;
        audio.value.currentTime = seekTime;
        store.dispatch("setCurrentTime", seekTime);
      }
    };
    watch(
      () => store.state.player.currentSong,
      (newSong) => {
        song.value = newSong;
        if (store.state.player.audio) {
          console.log("Cambiando la canción actual...", newSong);
          store.state.player.audio.src = newSong?.src || "";
          if (store.state.player.playing) {
            store.state.player.audio.play();
          }
        }
      }
    );

    watch(audio, () => {
      if (audio.value) {
        console.log("Audio cargado correctamente.", audio.value);
        audio.value.addEventListener("timeupdate", () => {
          if (audio.value) {
            store.dispatch("setCurrentTime", audio.value.currentTime);
            store.dispatch("setTotalTime", audio.value.duration);
          }
        });

        audio.value.addEventListener("ended", () => {
          if (repeat.value === 1) {
            if (audio.value) {
              audio.value.currentTime = 0;
              audio.value.play().catch((error: Error) => console.error(error));
            }
          } else if (repeat.value === 2) {
            nextSong();
          } else if (shuffle.value) {
            let playlist: Song[] = store.state.player.playlist;
            let randomIndex = Math.floor(Math.random() * playlist.length);
            let randomSong = playlist[randomIndex];

            while (randomSong === song.value) {
              randomIndex = Math.floor(Math.random() * playlist.length);
              randomSong = playlist[randomIndex];
            }

            store.dispatch("setCurrentSong", randomSong);
            if (audio.value) {
              audio.value.load();
              audio.value.oncanplaythrough = () => {
                if (audio.value) {
                  audio.value
                    .play()
                    .then(() => {
                      // La canción comenzó a reproducirse con éxito
                    })
                    .catch((error: Error) => {
                      // No se pudo comenzar a reproducir la canción
                      console.error(error);
                    });
                }
              };
            }
          } else {
            nextSong();
          }
        });
      }
    });
    const FREQUENCIES = computed(() => store.state.frecuencias.frequencies);
    // const FREQUENCIES = [440];
    
    const audioContext = new AudioContext();
    let mediaElementSource: MediaElementAudioSourceNode | null = null;
    let myProcessor: AudioWorkletNode | null = null;

    async function initializeAudioWorklet() {
      console.log("Loading audio worklet...");
      await audioContext.audioWorklet.addModule(
        process.env.BASE_URL + "MyAudioProcessor.worklet.js"
      );
      console.log("Audio worklet loaded.");
    }

    initializeAudioWorklet()
      .then(() => {
        console.log("AudioWorklet inicializado correctamente.");
        console.log("AudioContext:", audioContext);
      })
      .catch((error) => {
        console.error(
          "Error durante la inicialización del AudioWorklet:",
          error
        );
      });

    watch(
      () => store.state.player.playing,
      async (newPlaying) => {
       
        console.log("New playing state:", newPlaying);

        if (audioContext.state === "suspended") {
          await audioContext.resume();
        }

        // Inicializa mediaElementSource si aún no se ha hecho
        if (!mediaElementSource && store.state.player.audio) {
          mediaElementSource = audioContext.createMediaElementSource(
            store.state.player.audio
          );
          mediaElementSource.connect(audioContext.destination);
        }

        // Inicializa myProcessor si aún no se ha hecho
        if (!myProcessor && mediaElementSource) {
          myProcessor = new AudioWorkletNode(
            audioContext,
            "my-audio-processor"
          );
          mediaElementSource.connect(myProcessor);
          myProcessor.connect(audioContext.destination);
        }

        // Comentar para probar si esta bien
        const clonedFrequencies = JSON.parse(JSON.stringify(FREQUENCIES.value));
        if (!clonedFrequencies || clonedFrequencies.length === 0) {
          console.error("No hay frecuencias para enviar.");
          return;
        }
        // Hasta aqui
        if (newPlaying && store.state.player.audio) {
          store.state.player.audio.play();
          myProcessor!.port.postMessage({
            active: true,
            frequencies: clonedFrequencies,
            // frequencies: FREQUENCIES,
          });
          // y este log comentarlo tambien para probarlo
          console.log(
            "Mensaje enviado al AudioWorklet con frecuencias:",
            clonedFrequencies
          );
          console.log("Audio and beep started.");
        } else {
          store.state.player.audio.pause();
          myProcessor!.port.postMessage({
            frequencies: [],
          });
          console.log("Audio and beep paused.");
        }
      }
    );

    // let pendingFrequencies: number[] | null = null;
    // // Comentar para probar si está bien
    // watch(
    //   () => FREQUENCIES.value,
    //   (newValue) => {
    //     console.log(
    //       "Dentro del bloque watch. FREQUENCIES ha cambiado:",
    //       newValue
    //     );

    //     if (myProcessor) {
    //       const clonedFrequencies = JSON.parse(JSON.stringify(newValue));
    //       myProcessor.port.postMessage({
    //         active: store.state.player.playing,
    //         frequencies: clonedFrequencies,
    //       });
    //       console.log(
    //         "Mensaje enviado al AudioWorklet con frecuencias:",
    //         clonedFrequencies
    //       );
    //     } else {
    //       console.log(
    //         "myProcessor no está definido. Almacenando frecuencias para enviar más tarde."
    //       );
    //       pendingFrequencies = newValue;
    //     }
    //   }
    // );

    onMounted(() => {
      window.addEventListener("resize", handleResize);
    });

    const loadFrequenciesIfNeeded = async (songsId: number) => {
      if (!store.state.frecuencias.fetchedSongs.has(songsId)) {
        try {
          await store.dispatch("fetchFrequencies", songsId);
          console.log("Frecuencias cargadas desde el servidor");

          // Inicializa el AudioWorklet después de cargar las frecuencias
          await initializeAudioWorklet();
        } catch (error) {
          console.error("Error cargando las frecuencias:", error);
        }
      }
    };

    const togglePlay = async () => {
      const songsId = song.value ? song.value.Id : null;
      console.log(song.value);

      console.log("songsId:", songsId);

      if (songsId) {
        await loadFrequenciesIfNeeded(songsId);
      }

      if (!songsId) {
        console.error("No se pudo obtener el ID de la canción.");
        return;
      }

      store.dispatch("togglePlay");

      if (store.state.player.playing) {
        store.state.player.audio?.play();
      } else {
        store.state.player.audio?.pause();
      }
    };

    const toggleMute = () => {
      if (volume.value > 0) {
        prevVolume.value = volume.value;
        volume.value = 0;
      } else {
        volume.value = prevVolume.value;
      }
    };

    const toggleShuffle = () => {
      store.dispatch("toggleShuffle");
    };

    const prevSong = () => {
      store.dispatch("prevSong");
    };

    const nextSong = () => {
      store.dispatch("nextSong");
    };
    const toggleRepeat = () => {
      store.dispatch("toggleRepeat");
    };

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    return {
      song,
      playing,
      shuffle,
      repeat,
      volume,
      currentTime,
      totalTime,
      formatTime,
      seek,
      toggleShuffle,
      prevSong,
      togglePlay,
      nextSong,
      toggleRepeat,
      expandScreen,
      toggleMute,
      closeScreen,
      isExpanded,
      handleVolumeChange,
      mute,
      isDesktop,
      handleSeek,
      openReproductorGrande,
      fetchFrequenciesFromStore,
    };
  },
};
