
import { ref, onMounted, Ref, watch, onUpdated} from "vue";
import {useRouter} from "vue-router";
import SidebarMobile from "./SidebarMobile.vue";
import { useStore } from 'vuex';

export default {
  components: {
    SidebarMobile,
  },
  

  setup() {
    const router = useRouter(); // Asegúrate de que estás importando useRouter.
    const store = useStore(); // Declara `store` una sola vez.
    const isDesktop = ref(window.matchMedia('(min-width: 768px)').matches);
    const sideBar = ref(false);
    const open = ref(false);
    const playlists_sidebar = ref([]);
    const collectionHeight = ref(0);
    const collectionRef = ref<HTMLElement | null>(null);
    const calculatedHeight = ref('');
    const showElements = ref(true);
    const playlistsLoaded = ref(false);
    const goToPlaylist = (playlistId:number) => {
      router.push(`/playlist/${playlistId}`);
    };

    


    watch(playlists_sidebar, (newValue, oldValue) => {
      console.log("Playlists changed: ", newValue);
    });
  const fetchPlaylists = async () => {
  const response = await fetch('https://wavesmusic.app/api/playlists');
  const data = await response.json();
  playlists_sidebar.value = data; // Vue debería reaccionar a este cambio.
};

    





    const calculateHeight = (): void => {
      const windowHeight = window.innerHeight;
      const containerHeight = windowHeight - 96;

      calculatedHeight.value = `${containerHeight}px`;
    };

    const toggleSidebar = (): void => {
      sideBar.value = !sideBar.value;
    };

    const toggleCollapse = (): void => {
      open.value = !open.value;

      collectionHeight.value = open.value
        ? collectionRef.value?.offsetHeight || 0
        : 0;
    };
    
    const calcularAlturaContenedorHijo = () => {
      const contenedorPadre = document.querySelector(
        ".contenedor-padre"
      ) as HTMLElement;
      const contenedorHijo = document.querySelector(
        ".contenedor-hijo:last-child"
      ) as HTMLElement;

      if (contenedorPadre && contenedorHijo) {
        const alturaContenedorPadre = contenedorPadre.offsetHeight;
        const alturaContenedorHijo =
          alturaContenedorPadre - collectionHeight.value;

        contenedorHijo.style.height = `${alturaContenedorHijo}px`;
      }
    };

    onMounted(async () => {
      calculateHeight(); // Ejecuta primero la función que no es asíncrona.

      const userString = localStorage.getItem('user');
      const userObject = JSON.parse(userString || '{}');
      const userId = userObject.id;

      if (userId) {
        await store.dispatch('getPlaylists', userId);
        playlists_sidebar.value = store.state.user.playlists;
      }

      try {
        await fetchPlaylists();
        playlistsLoaded.value = true;
      } catch (error) {
        console.error("Hubo un error al cargar las playlists:", error);
      }
    });


    const handleResize = () => {
      // Define the handleResize function here
    };

    const toggleSearch = () => {
      // Define the toggleSearch function here
    };

    return {
      isDesktop,
      sideBar,
      open,
      collectionHeight,
      toggleSidebar,
      toggleCollapse,
      collectionRef,
      calculatedHeight,
      showElements,
      toggleSearch, // Ensure this is defined
      playlists_sidebar,
      fetchPlaylists,
      goToPlaylist,
      playlistsLoaded
    };
  },
};
