
import { computed, SetupContext } from 'vue';
import { useStore } from 'vuex';

interface Props {
  song?: { 
    name: string;
    artist: string;
    src: string;
    cover: string;
  } | null;
}

export default {
  props: {
    song: {
      type: Object as () => Props['song'],
      required: true,
      default: null,
    },
  },
  setup(props: Props, context: SetupContext) {
    const store = useStore();
    const playing = computed(() => store.state.player.playing);
    const currentTime = computed(() => store.state.player.currentTime);
    const totalTime = computed(() => store.state.player.totalTime);
    const prevSong = () => {
      context.emit('prevSong');
    };
    const nextSong = () => {
      context.emit('nextSong');
    };
    const togglePlay = () => {
      store.dispatch('togglePlay');
    };

    const formatTime = (time: number) => {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const onSeek = (event: MouseEvent) => {
      const progressBar = event.currentTarget as HTMLElement;
      const rect = progressBar.getBoundingClientRect();
      const clickX = event.clientX - rect.left;
      const barWidth = rect.width;
      const seekTime = (clickX / barWidth) * totalTime.value;
      context.emit('seek', seekTime);
    };

    return { playing, togglePlay, currentTime, totalTime, formatTime, onSeek, prevSong, nextSong };
  },
};
