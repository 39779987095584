import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-291b4375"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view-container" }
const _hoisted_2 = { class: "songbg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_myCollectionNav = _resolveComponent("myCollectionNav")!
  const _component_myCollectionSongsComponent = _resolveComponent("myCollectionSongsComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_myCollectionNav),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_myCollectionSongsComponent)
    ])
  ]))
}