
import router from "@/router";
import { defineComponent, ref, computed } from "vue";
import { onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Song } from "../../store/types/types";

interface SearchResults {
  users: { id: string; name: string }[];
  artists: { id: string; name: string }[];
  albums: { id: string; title: string }[];
  songs: { id: string; title: string }[];
  playlists: { id: string; name: string }[];
}

export default defineComponent({
  setup() {
    const store = useStore();
    const searchQuery = ref("");
    const results = ref<SearchResults | null>(null);
    const searchResults = computed(() => store.state.search.results);
    const isPlaying = computed(() => store.state.player.playing);
    const currentSong = computed(() => store.state.player.currentSong);

    const goToUser = async (id: number | string) => {
      await store.dispatch("fetchUserId", id);
      router.push({ name: "userProfile", params: { id } });
      searchQuery.value = "";
    };

    const playSong = (song: Song) => {
    if (isPlaying.value && currentSong.value === song) {
      store.dispatch('togglePlay');
    } else {
      store.dispatch('playSong', song);
    }
    searchQuery.value = "";
  };

    const goToArtist = async (id: number | string) => {
      await store.dispatch("fetchArtist", id);
      router.push({ name: "artistProfile", params: { id } });
      searchQuery.value = "";
    };

    const goToPlaylist = async (id: number | string) => {
      await store.dispatch("fetchPlaylist", id);
      router.push({ name: "playlist", params: { id } });
      searchQuery.value = "";
    };

    const hideSearchResults = (event: MouseEvent) => {
      const searchWrapper = document.querySelector(".search-bar-wrapper");
      if (searchWrapper && !searchWrapper.contains(event.target as Node)) {
        searchQuery.value = "";
      }
    };

    onMounted(() => {
      document.addEventListener("click", hideSearchResults);
    });

    onUnmounted(() => {
      document.removeEventListener("click", hideSearchResults);
    });

    const search = () => {
      if (searchQuery.value) {
        const token = localStorage.getItem("userToken");
        console.log(token);

        if (token) {
          store.dispatch("fetchResults", searchQuery.value);

          if (store.state.results && "users" in store.state.results) {
            results.value = { ...store.state.search.results }; // Actualizar la referencia en lugar de la propiedad computada
            console.log(results.value);
          }
        } else {
          console.error("No se encontró el token.");
        }
      }
    };

    return {
      searchQuery,
      searchResults,
      search,
      goToUser,
      goToArtist,
      hideSearchResults,
      playSong,
      goToPlaylist,
    };
  },
});
