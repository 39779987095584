import axios from 'axios';
import { MutationTree, ActionTree } from 'vuex';
import { RootState } from '../types/types';
import { Song } from '../types/types';
import { ApiSong } from '../types/types';
import { Artist } from '../types/types';
import { Album } from '../types/types';
import { Playlist } from '../types/types';

export interface PlaylistState {
  playlist: Playlist | null;
//   songs: Song[];
}

const state: PlaylistState = {
  playlist: null,
//   songs: [],
};

const mutations: MutationTree<PlaylistState> = {
  setPlaylist(state, playlist: Playlist) {
    state.playlist = playlist;
  },
//   setSongs(state, songs) {
//     state.songs = songs;
//   },
};

const actions: ActionTree<PlaylistState, RootState> = {
  async fetchPlaylist({ commit }, id: number | string) {
    const response = await axios.get(`https://wavesmusic.app/api/playlists/${id}`);
    const playlist = response.data;
    // Aquí procesas las canciones de la playlist
    playlist.Songs = playlist.Songs.map((song: Song) => ({
      ...song,
      Id: song.Id,
      artist: song.artist,
      src: song.audio_file_url,
      name: song.title,
      cover: song.album_cover_url,
      
    }));
    commit('setPlaylist', response.data);
  },
//   async fetchSongsByPlaylist({ commit }, id: number) {
//     const response = await axios.get(`https://wavesmusic.app/api/playlists/${id}/songs`);

//     const songs = response.data.map((song: ApiSong) => ({
//       name: song.title,
//       artist: song.artist,  
//       src: song.audio_file_url,
//       cover: song.album_cover_url,
//       Id: song.Id,
//     }));

//     commit('setSongs', songs);
//   },


};

export default {
  state,
  mutations,
  actions,
};
