
import SidebarComponent from "./components/Sidebar/SidebarNavigation.vue";
import MiniReproductor from "./components/reproductor/MiniReproductor.vue";
import DarkModeToggle from "./components/DarkModeToggle.vue";
import NavbarNavigation from "./components/Navbar/NavbarNavigation.vue";
import { onMounted } from "vue";

import { useStore } from "vuex";

export default {
  components: {
    DarkModeToggle,
    MiniReproductor,
    SidebarComponent,
    NavbarNavigation,
  },
  setup() {
    const store = useStore();
    
    onMounted(() => {
      store.dispatch("initializePlaylist");
      store.dispatch("initializeAudio");
      // Cuando la aplicación se crea, verifica si hay un token y datos de usuario en el almacenamiento local
      const userToken = localStorage.getItem("userToken");
      const userDataString = localStorage.getItem("user");

      if (userToken && userDataString) {
        // Los datos se almacenan como una cadena, así que tienes que convertirlos de vuelta a un objeto
        const user = JSON.parse(userDataString);

        // Si hay un token y datos de usuario, actualiza el estado en la tienda Vuex
        store.commit("setToken", userToken);
        store.commit("setUser", user);
      }
      console.log(store.state.user); // Imprime el usuario
      console.log(JSON.stringify(store.state.user, null, 2));
      //console.log(store.state.userToken); // Imprime el token
    });
  },
};
