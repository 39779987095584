import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-008d585a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fixed iconplug top-0 right-0 m-4" }
const _hoisted_2 = {
  key: 0,
  class: "fas fa-sun text-yellow-500"
}
const _hoisted_3 = {
  key: 1,
  class: "fas fa-moon text-gray-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.toggleDarkMode && $setup.toggleDarkMode(...args))),
      class: "focus:outline-none z-10000"
    }, [
      ($setup.isLightMode)
        ? (_openBlock(), _createElementBlock("i", _hoisted_2))
        : (_openBlock(), _createElementBlock("i", _hoisted_3))
    ])
  ]))
}