
  import { defineComponent, computed, ref, onMounted } from 'vue';
  import { useStore } from 'vuex';
  
  export default defineComponent({
    setup() {
      const store = useStore();
      const playlistsToShow = ref(5);
  
      // Cargamos las playlists cuando se monta el componente.
      onMounted(() => {
        const userString = localStorage.getItem('user');
        const userObject = JSON.parse(userString || '{}');
        const userId = userObject.id;
        if (userId) {
          store.dispatch('getPlaylists', userId);
        }
      });
  
      const myPlaylists = computed(() => store.state.user?.playlists || []);
  
      const showMorePlaylists = () => {
        playlistsToShow.value += 5;
      };
  
      const groupedMyPlaylists = computed(() => {
        let groups: typeof myPlaylists.value[][] = [];
        let totalGroupsToShow = Math.ceil(playlistsToShow.value / 5);
  
        for (let i = 0; i < totalGroupsToShow; i++) {
          let start = i * 5;
          let end = start + 5;
          groups.push(myPlaylists.value.slice(start, end));
        }
  
        return groups;
      });
  
      return {
        showMorePlaylists,
        groupedMyPlaylists
      };
    }
  });
  