
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'MyCollectionNav',
  setup() {
    const navbar = ref<HTMLElement | null>(null);

    const toggleNav = () => {
      if (navbar.value) {
        if (navbar.value.classList.contains('active')) {
          navbar.value.classList.remove('active');
        } else {
          navbar.value.classList.add('active');
        }
      }
    };

    return {
      navbar,
      toggleNav
    }
  }
})
