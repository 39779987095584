import { MutationTree, ActionTree, GetterTree } from "vuex";
import { RootState } from "../types/types";
import axios from "axios";
import { Song } from "../types/types";
import { ApiSong } from "../types/types";
import { Album } from "../types/types";
import { Playlist } from "../types/types";

export interface HistorialItem {
  usuarioId: number;
  cancionId?: number;
  albumId?: number;
  playlistId?: number;
  type: string;
}

export interface PlayerState {
  playlist: (Song | Album | Playlist)[];

  currentSong: Song | Album | Playlist | null;
  currentAlbum: Album | null;
  playing: boolean;
  shuffle: boolean;
  repeat: number;
  volume: number;
  shuffleMode: string;
  mute: boolean;
  currentTime: number;
  totalTime: number;
  currentType: string;
  type: "song" | "album" | "playlist" | null;
  audio: HTMLAudioElement;
  song: Song | null;
  history: (Song | Album | Playlist)[];
}

const state: PlayerState = {
  playlist: [],
  currentSong: null,
  currentAlbum: null,
  playing: false,
  shuffle: false,
  repeat: 0,
  volume: 50,
  shuffleMode: "none",
  mute: false,
  currentTime: 0,
  totalTime: 0,
  currentType: "",
  type: null,
  audio: new Audio(),
  song: null,
  history: [],
};

const mutations: MutationTree<PlayerState> = {
  setAudio(state, audio: HTMLAudioElement) {
    state.audio = audio;
  },

  addToPlaylist(state, song: Song) {
    state.playlist.push(song);
  },

  setPlaylist(state, playlist: Song | Album | Playlist[]) {
    console.log("setplayliststate IN", state.playlist)
   
    state.playlist = Array.isArray(playlist) ? playlist : [playlist];
    console.log("setplayliststate", state.playlist)
  },

  setCurrentSong(state, song: Song) {
    console.log("Current Song", song);
    state.currentSong = song;
    // almacenar la canción actual en localStorage
    localStorage.setItem("lastPlayedSong", JSON.stringify(song));
    // almacenar la posición actual de la canción en localStorage
    localStorage.setItem("lastPlayedTime", JSON.stringify(state.currentTime));
  },
  setPlaying(state, playing: boolean) {
    state.playing = playing;
  },
  setShuffle(state, shuffle: boolean) {
    state.shuffle = shuffle;
    state.shuffleMode = shuffle ? "shuffle" : "none";
  },
  setRepeat(state, repeat: number) {
    state.repeat = repeat;
  },
  setVolume(state, volume: number) {
    state.volume = volume;
    state.mute = volume === 0;
  },
  setMute(state, mute) {
    state.mute = mute;
  },
  setCurrentTime(state, time) {
    state.currentTime = time;
  },
  setTotalTime(state, time) {
    state.totalTime = time;
  },
  setCurrentAlbum(state, album: Album) {
    state.currentAlbum = album;
  },
};

const getters: GetterTree<PlayerState, RootState> = {
  getCurrentType: (state) => state.currentType,
};
const actions: ActionTree<PlayerState, RootState> = {
  initializeAudio({ commit, dispatch }) {
    const audio = new Audio();
    audio.crossOrigin = "anonymous"; // Establecer el atributo crossorigin
    audio.addEventListener("timeupdate", () => {
      dispatch("setCurrentTime", audio.currentTime);
      dispatch("setTotalTime", audio.duration);
    });
    commit("setAudio", audio);
  },

  // ...
  async initializePlaylist({ commit, state }) {
    try {
      const response = await axios.get("https://wavesmusic.app/api/songs/");
      if (response.status !== 200) {
        throw new Error("Error al recuperar las canciones");
      }

      const apiSongs: ApiSong[] = response.data;

      console.log("API Songs:", apiSongs);

      const songs: Song[] = apiSongs.map((song) => ({
        Id: song.Id,
        name: song.title,
        artist: song.artist,
        src: song.audio_file_url,
        cover: song.album_cover_url,
        type: song.type,
        artistId: song.artistId,
      }));
      console.log("Mapped Songs:", songs);
      // Comprobar si hay una canción almacenada en localStorage
      const lastPlayedSong = localStorage.getItem("lastPlayedSong");

      if (lastPlayedSong && lastPlayedSong !== "undefined") {
        // Si hay una canción y no es "undefined", la convertimos de vuelta a un objeto y la usamos
        commit("setCurrentSong", JSON.parse(lastPlayedSong));

        const lastPlayedTime = localStorage.getItem("lastPlayedTime");
        if (lastPlayedTime && lastPlayedTime !== "undefined") {
          // Si hay un tiempo guardado y no es "undefined", lo convertimos de vuelta a un número y lo usamos
          commit("setCurrentTime", Number(JSON.parse(lastPlayedTime)));
          if (state.audio) {
            state.audio.currentTime = Number(JSON.parse(lastPlayedTime));
          }
        }
      } else {
        // Si no hay ninguna canción, elegimos una al azar
        const randomIndex = Math.floor(Math.random() * songs.length);
        commit("setCurrentSong", songs[randomIndex]);
      }

      // Agregamos las canciones al estado
      commit("setSongs", songs);
    } catch (error) {
      console.error("Error fetching songs: ", error);
    }
  },
  async playPlaylistFromIndex({ commit, dispatch }, { playlistId, index }) {
    try {
      // Primero, obtenemos la playlist por su ID usando una acción existente
      // Asumimos que hay una acción en el módulo playlist que ya hace esto.
      await dispatch('fetchPlaylist', playlistId, { root: true });

      // Luego, verificamos si la playlist se ha cargado correctamente
      const playlist = this.state.playlist.playlist; // Obtenemos la playlist del estado
      if (!playlist || !playlist.Songs || playlist.Songs.length === 0) {
        throw new Error('Playlist está vacía o no se encontró');
      }

      // Comprobamos que el índice es válido
      if (index < 0 || index >= playlist.Songs.length) {
        throw new Error('Índice fuera de los límites de la playlist');
      }

      // Establecemos la playlist en el reproductor
      commit('setPlaylist', playlist.Songs);

      // Establecemos la canción actual basada en el índice y comenzamos la reproducción
      const songToPlay = playlist.Songs[index];
      commit("setCurrentSong", songToPlay);
      commit("setPlaying", true);
    } catch (error) {
      console.error('Error al reproducir la playlist desde el índice:', error);
      // Manejo de errores, como mostrar un mensaje al usuario
    }
  },
  playAllSongs({ commit }, payload: Song[] | Playlist) {
    // Verificar si el payload es del tipo Playlist
    if ((<Playlist>payload).Songs && Array.isArray((<Playlist>payload).Songs)) {
      // Si es una Playlist, utiliza la propiedad 'Songs'
      commit("setPlaylist", payload);
      commit("setCurrentSong", (<Playlist>payload).Songs[0]);
      commit("setPlaying", true);
    } else if (Array.isArray(payload)) {
      // Si es un arreglo de Song, procesa como tal
      commit("setPlaylist", { Songs: payload }); // Envuelve las canciones en un objeto Playlist
      commit("setCurrentSong", payload[0]);
      commit("setPlaying", true);
    } else {
      // Manejar otros casos o lanzar un error si es necesario
      console.error("El payload proporcionado no coincide con los tipos Song[] o Playlist.");
    }
  },
  playSong({ commit, dispatch, state }, song: Song) {
    // Verificar que la canción tiene un id
    if (!song.Id) {
      console.error("La canción no tiene un id:", song);
      return;
    }


    // Si la canción se agrega correctamente a la lista de reproducción, la configuramos como la canción actual y comenzamos a reproducirla

    dispatch("addToHistorialSongs", song);

    commit("setCurrentSong", song);
    commit("setPlaying", true);
    
  },

  
  playAlbum({ commit, state }, album: Album) {
    // Limpiar la lista de reproducción actual

    commit("setPlaylist", []);

    // Recuperar el álbum con todas sus canciones del backend
    axios
      .get(`https://wavesmusic.app/api/albums/${album.id}`)
      .then((response) => {
        const album = response.data;

        // Convertir ApiSong a Song
        const songs = album.Songs.map((apiSong: ApiSong) => ({
          Id: apiSong.Id,
          name: apiSong.title,
          artist: apiSong.artist,
          src: apiSong.audio_file_url,
          cover: apiSong.album_cover_url,
          type: apiSong.type,
          artistId: apiSong.artistId,
        }));

        // Agregar todas las canciones del álbum a la lista de reproducción
        for (const song of songs) {
          commit("setCurrentAlbum", album);

          commit("addToPlaylist", song);
          console.log(state.playlist);
        }

        // Comprobar si hay canciones en la lista de reproducción
        if (state.playlist.length > 0) {
          // Si hay canciones, establecer la primera como la canción actual y empezar a reproducir

          commit("setCurrentSong", state.playlist[0]);
          commit("setPlaying", true);
        }
      })
      .catch((error) => {
        console.error("Error al recuperar el álbum:", error);
      });
  },

  setPlaying({ commit }, status: boolean) {
    commit("setPlaying", status);
  },
  addToPlaylist({ commit }, song: Song) {
    commit("addToPlaylist", song);
  },
  setPlaylist({ commit }, playlist: Song | Album | Playlist[]) {
    commit("setPlaylist", playlist);
  },
  setCurrentSong({ commit }, song: Song) {
    commit("setCurrentSong", song);
  },
  setCurrentAlbum({ commit }, album: Album) {
    commit("setCurrentAlbum", album);
  },
  togglePlay({ state, commit }) {
    commit("setPlaying", !state.playing);
  },

  toggleShuffle({ state, commit }) {
    commit("setShuffle", !state.shuffle);
  },
  toggleRepeat({ state, commit }) {
    commit("setRepeat", (state.repeat + 1) % 3);
  },

  setVolume({ commit }, volume: number) {
    commit("setVolume", volume);
  },
  nextSong({ state, commit }) {
    if (!state.currentSong) {
      return;
    }
    console.log("Nextsong state", state.playlist);
    let index = state.playlist[0].Songs.indexOf(state.currentSong);

    if (state.shuffle) {
      // Seleccionar una canción aleatoria de la lista de reproducción.
      let randomIndex = Math.floor(Math.random() * state.playlist.length);
      while (randomIndex === index) {
        randomIndex = Math.floor(Math.random() * state.playlist.length);
      }
      commit("setCurrentSong", state.playlist[0].Songs[randomIndex]);
    } else if (state.repeat === 1) {
      // En el modo de repetir 1, simplemente repetimos la canción actual.
      commit("setCurrentSong", state.currentSong);
    } else if (state.repeat === 2 || index < state.playlist[0].Songs.length - 1) {
      // En el modo de repetir 2 o si no estamos en la última canción, avanzamos a la siguiente canción.
      index = (index + 1) % state.playlist[0].Songs.length;
      commit("setCurrentSong", state.playlist[0].Songs[index]);
    } else {
      // Si no estamos en modo repeat y estamos en la última canción, detenemos la reproducción.
      commit("setPlaying", false);
    }
  },

  prevSong({ state, commit }) {
    if (!state.currentSong) {
      return;
    }

    let index = state.playlist.indexOf(state.currentSong);
    if (state.repeat === 1) {
      commit("setCurrentSong", state.currentSong);
    } else if (index > 0) {
      index--;
      commit("setCurrentSong", state.playlist[index]);
    } else {
      index = state.playlist.length - 1;
      commit("setCurrentSong", state.playlist[index]);
    }
  },

  setCurrentTime({ commit }, time) {
    commit("setCurrentTime", time);
  },
  setTotalTime({ commit }, time) {
    commit("setTotalTime", time);
  },
  setMute({ commit }, mute) {
    commit("setMute", mute);
  },

playSongsFromIndex({ commit, state }, { songs, index }) {
  commit("setPlaylist", songs);
  commit("setCurrentSong", songs[index]);
  commit("setPlaying", true);
},
};
export default {
  state,
  mutations,
  actions,
  getters,
};
