import axios from "axios";
import { MutationTree, ActionTree } from "vuex";
import { RootState } from "../types/types";

export interface User {
  id: number;
  nickname: string;
  name: string;
  last_name: string;
  email: string;
  phone: string;
  password: string;
  fecha_registro: Date;
  fecha_ultimo_acceso: Date;
  edad: Date;
  pais: string;
  suscripcion: number;
  fecha_suscripcion: Date;
  type: string;
  code: string;
  photo: string;
}

export interface UserState {
  user: User | null;
  token: string | null;
  cancionesMasEscuchadas: any[];
  playlists: any[]; // Nueva propiedad
}

const state: UserState = {
  user: null,
  token: null,
  cancionesMasEscuchadas: [],
  playlists: [], // Estado inicial
};

const mutations: MutationTree<UserState> = {
  setUser(state, user: User) {
    state.user = user;
  },
  setToken(state, token: string) {
    // Agrega esta mutación para configurar el token
    state.token = token;
  },
  setUserId(state, user: User) {
    state.user = user;
  },
  setCancionesMasEscuchadas(state, canciones) { // Nueva mutación
    state.cancionesMasEscuchadas = canciones;
  },
  setPlaylists(state, playlists) {
    state.playlists = playlists;
  },
  addPlaylist(state, playlist) {
    state.playlists.push(playlist);
  },
  }


const actions: ActionTree<UserState, RootState> = {
    async fetchUser({ commit }, id: number | null | undefined) {
        if (id === null || id === undefined) {
          console.error("fetchUser fue llamado con un id inválido:", id);
          return;
        }
        try {
          const response = await axios.get(
            `https://wavesmusic.app/api/users/${id}`
          );
          const apiUser = response.data;
          const user: User = {
            id: apiUser.Id, // Nota el mapeo de Id a id aquí
            name: apiUser.name || '',
            last_name: apiUser.last_name || '',
            email: apiUser.email || '',
            nickname: apiUser.nickname || '',
            phone: apiUser.phone || '',
            password: '',
            fecha_registro: apiUser.fecha_registro || undefined,
            fecha_ultimo_acceso: apiUser.fecha_ultimo_acceso || undefined,
            edad: apiUser.edad || undefined,
            pais: apiUser.pais || '',
            suscripcion: apiUser.suscripcion || 0,
            fecha_suscripcion: apiUser.fecha_suscripcion || undefined,
            type: apiUser.type || '',
            code: apiUser.code || '',
            photo: apiUser.photo || '',
          };
          localStorage.setItem("user", JSON.stringify(user));
          commit("setUser", user);
        } catch (error) {
          console.error("Failed to fetch user:", error);
        }
      },
      async fetchUserId({ commit }, id: number | string) {
        const response = await axios.get(`https://wavesmusic.app/api/users/${id}`);
        commit('setUserId', response.data);
    },
    async obtenerCancionesMasEscuchadasPorUsuario({ commit }, id: number | null | undefined) {
      console.log("Usuario ID en obtenerCancionesMasEscuchadasPorUsuario:", id); // Línea agregada para imprimir el usuarioId
      try {
        console.log("ID antes de llamar a axios:", id);
        const response = await axios.get(`https://wavesmusic.app/api/escuchas/canciones/masEscuchadas/${id}`);
        console.log("Respuesta de axios:", response.data.cancionesMasEscuchadas)
        const songs = response.data.cancionesMasEscuchadas[0].Songs;
        console.log("Canciones:", songs)
        commit('setCancionesMasEscuchadas', songs);
      } catch (error) {
        console.error('Error al obtener las canciones más escuchadas:', error);
      }
    },
    async createPlaylist({ commit, state }, { playlistName, userId }: { playlistName: string, userId: number }) {
      try {
        const response = await axios.post(`https://wavesmusic.app/api/users/createPlaylists/${userId}`, {
          userId: state.user?.id,
          name: playlistName,
        });
        commit('addPlaylist', response.data);
      } catch (error) {
        console.error('Error al crear la playlist:', error);
      }
    },
    
    async getPlaylists({ commit }, userId: number) {
      try {
        const response = await axios.get(`https://wavesmusic.app/api/users/${userId}/playlists`);
        commit('setPlaylists', response.data);
      } catch (error) {
        console.error('Error al obtener las playlists:', error);
      }
    },

  };
  

export default {
  state,
  mutations,
  actions,
};