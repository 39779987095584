
  import { defineComponent, onMounted, computed, ref } from 'vue';
  import { useStore } from 'vuex';
  import { Song } from "../../../store/types/types";
  
  export default defineComponent({
    setup() {
      const store = useStore();
      const windowWidth = ref(window.innerWidth);
      const songsToShow = ref(windowWidth.value <= 500 ? 4 : 5);
  
      const historialCanciones = computed(() => store.state.historial.historySongs as Song[]);
      const isPlaying = computed(() => store.state.player.playing);
      const currentSong = computed(() => store.state.player.currentSong);
  
      const playSong = (song: Song) => {
        if (isPlaying.value && currentSong.value === song) {
          store.dispatch('togglePlay');
        } else {
          store.dispatch('playSong', song);
        }
      };
      const playAllIconClass = computed(() => {
      return store.state.playingAllHistory ? 'mdi mdi-pause play-all-icon' : 'mdi mdi-play play-all-icon';
    });

    const playAllSongs = () => {
      if (!store.state.playingAllHistory) {
        store.dispatch('playAllHistorySongs');
      } else {
        store.commit('SET_PLAYING_ALL_HISTORY', false);
        // Aquí podrías añadir lógica para pausar todas las canciones
      }
    };
      const updateWidth = () => {
        windowWidth.value = window.innerWidth;
        if (windowWidth.value <= 500) {
          songsToShow.value = 4;
        } else {
          songsToShow.value = 5;
        }
      };
  
      const showMoreSongs = () => {
        if (windowWidth.value <= 500) {
          songsToShow.value += 4;
        } else {
          songsToShow.value += 5;
        }
      };
  
      const groupedSongs = computed(() => {
        let groups: Song[][] = [];
        let totalGroupsToShow = Math.ceil(songsToShow.value / (windowWidth.value <= 500 ? 4 : 5));
        
        for (let i = 0; i < totalGroupsToShow; i++) {
          let start = i * (windowWidth.value <= 500 ? 4 : 5);
          let end = start + (windowWidth.value <= 500 ? 4 : 5);
          groups.push(historialCanciones.value.slice(start, end));
        }
  
        return groups;
      });
  
      onMounted(() => {
        window.addEventListener('resize', updateWidth);
  
        const userString = localStorage.getItem("user");
        const userObject = JSON.parse(userString || "{}");
        const userId = userObject.id;
  
        if (userId) {
          store.dispatch("fetchHistorySongs", userId);
        } else {
          console.error("UserId no encontrado en el localStorage");
        }
      });
  
      return {
        historialCanciones,
        isPlaying,
        currentSong,
        playSong,
        groupedSongs,
        playAllIconClass,
      playAllSongs,
        showMoreSongs
      };
    }
  });
  