
import { defineComponent, computed, ref, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { Song } from '../../../store/types/types';
import { useRoute } from 'vue-router';

export default defineComponent({
setup() {
  const store = useStore();
  const route = useRoute();
  const userId = route.params.userId;

  const isPlaying = computed(() => store.state.player.playing);
  const currentSong = computed(() => store.state.player.currentSong);
  const favoriteSongs = computed(() => store.state.favoritos.favoriteSongs);
  const windowWidth = ref(window.innerWidth);

  // Nuevo ref para llevar un registro de cuántas canciones mostrar
  const songsToShow = ref(windowWidth.value <= 500 ? 4 : 5);

  const playSong = (song: Song) => {
    if (isPlaying.value && currentSong.value === song) {
      store.dispatch('togglePlay');
    } else {
      store.dispatch('playSong', song);
    }
  };

  const updateWidth = () => {
    windowWidth.value = window.innerWidth;
    if (windowWidth.value <= 500) {
      songsToShow.value = 4;
    } else {
      songsToShow.value = 5;
    }
  }

  const showMoreSongs = () => {
if (windowWidth.value <= 500) {
  songsToShow.value += 4;
} else {
  songsToShow.value += 5;
}
}

  const groupedSongs = computed(() => {
let groups = [];
let totalGroupsToShow = Math.ceil(songsToShow.value / (windowWidth.value <= 500 ? 4 : 5));

for (let i = 0; i < totalGroupsToShow; i++) {
  let start = i * (windowWidth.value <= 500 ? 4 : 5);
  let end = start + (windowWidth.value <= 500 ? 4 : 5);
  groups.push(favoriteSongs.value.slice(start, end));
}

return groups;
});

  onMounted(async () => {
window.addEventListener('resize', updateWidth);
 // Obtén el objeto de usuario desde el localStorage (reemplaza 'clave' con la clave real)
 // Obtén el objeto de usuario desde el localStorage
const userString = localStorage.getItem('user');
const userObject = JSON.parse(userString || '{}');
const userId = userObject.id; // Suponiendo que el ID está directamente en el objeto de usuario

if (userId) {
  await store.dispatch('fetchFavoriteSongs', userId);
} else {
  console.error('UserId no encontrado en el localStorage');
}
updateWidth(); // Inicializa songsToShow aquí, después de obtener los datos
});

  onUnmounted(() => {
    window.removeEventListener('resize', updateWidth);
  });

  const favoriteSongsLimited = computed(() => {
    return favoriteSongs.value.slice(0, songsToShow.value);
  });

  return {
    isPlaying,
    currentSong,
    playSong,
    favoriteSongsLimited,
    userId,
    favoriteSongs,  
    showMoreSongs,  
    songsToShow,
    groupedSongs   
  };
},
});

