import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f343be8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-background" }
const _hoisted_2 = { class: "nav-features" }
const _hoisted_3 = { class: "navburger" }
const _hoisted_4 = { ref: "navbar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", null, [
          _createVNode(_component_router_link, {
            to: "/myCollection",
            class: "header-link"
          }, {
            default: _withCtx(() => [
              _createTextVNode("My Collection")
            ]),
            _: 1
          })
        ]),
        _createElementVNode("button", {
          class: "burger-menu",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleNav && _ctx.toggleNav(...args)))
        }, "☰")
      ]),
      _createElementVNode("nav", _hoisted_4, [
        _createVNode(_component_router_link, {
          to: "/myCollection/recent",
          "active-class": "active-link"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Recent Played")
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: "/myCollection/mixes",
          "active-class": "active-link"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Mixes & Playlists")
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: "/myCollection/songs",
          "active-class": "active-link"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Songs")
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: "/myCollection/albums",
          "active-class": "active-link"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Albums")
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: "/myCollection/artists",
          "active-class": "active-link"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Artists")
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: "/myCollection/myPlaylist",
          "active-class": "active-link"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" My Playlist")
          ]),
          _: 1
        })
      ], 512)
    ])
  ]))
}