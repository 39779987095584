
import myCollectionNav from '@/components/myCollection/myCollectionNav.vue'
import myCollectionArtistsComponent from '@/components/myCollection/Artists/myCollectionArtistsComponent.vue'

export default {
  components: {
 myCollectionNav,
 myCollectionArtistsComponent,
},
}

