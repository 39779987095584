
import myCollectionNav from '@/components/myCollection/myCollectionNav.vue'
import myCollectionSongsComponent from '@/components/myCollection/Songs/myCollectionSongsComponent.vue'
export default {
   components: {
  myCollectionNav,
  myCollectionSongsComponent,
 },
}

