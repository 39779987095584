
import { defineComponent, computed, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const store = useStore();
    const favoriteArtists = computed<
      Array<{ photo_url: string; name: string }>
    >(() => store.state.favoritos.favoriteArtists);

    const windowWidth = ref(window.innerWidth);
    const artistsToShow = ref(windowWidth.value <= 500 ? 4 : 5);

    const showMoreArtists = () => {
      if (windowWidth.value <= 500) {
        artistsToShow.value += 4;
      } else {
        artistsToShow.value += 5;
      }
    };
    const redirectToArtist = (artistId: string) => {
      const baseUrl = process.env.NODE_ENV === 'production' ? 'https://wavesmusic.app' : 'http://localhost:8080';
      window.location.href = `${baseUrl}/artist/${artistId}`;
    };
    const updateWidth = () => {
      windowWidth.value = window.innerWidth;
      if (windowWidth.value <= 500) {
        artistsToShow.value = 4;
      } else {
        artistsToShow.value = 5;
      }
    };

    onMounted(async () => {
      window.addEventListener("resize", updateWidth);
      const userString = localStorage.getItem("user");
      const userObject = JSON.parse(userString || "{}");
      const userId = userObject.id;

      if (userId) {
        await store.dispatch("fetchFavoriteArtists", userId as any);
      } else {
        console.error("UserId no encontrado en el localStorage");
      }
      updateWidth();
    });

    const groupedArtists = computed(() => {
      let groups: { photo_url: string; name: string }[][] = [];

      let totalGroupsToShow = Math.ceil(
        artistsToShow.value / (windowWidth.value <= 500 ? 4 : 5)
      );

      for (let i = 0; i < totalGroupsToShow; i++) {
        let start = i * (windowWidth.value <= 500 ? 4 : 5);
        let end = start + (windowWidth.value <= 500 ? 4 : 5);
        groups.push(favoriteArtists.value.slice(start, end));
      }

      return groups;
    });

    return {
      favoriteArtists,
      artistsToShow,
      groupedArtists,
      showMoreArtists,
      redirectToArtist
      };
  },
});
