
import { computed, onMounted } from "vue";
import { useStore } from "vuex";

import PlaylistBanner from "../../components/playlist/PlaylistBanner.vue";
import PlaylistBody from '../../components/playlist/PlaylistBody.vue';
import { useRoute } from "vue-router";


export default {
  components: {
    PlaylistBanner,
    PlaylistBody,
  },
  setup() {
    const store = useStore();
    const playlist = computed(() => store.state.playlist.playlist);

    const route = useRoute();
    const id = Number(route.params.id);

    onMounted(async () => {
      console.log("route.params:", route.params);

      const id = Number(route.params.id);
      console.log("onMounted: Llamando a fetchPlaylist con ID:", id);

      if (id) {
        await store.dispatch("fetchPlaylist", id);
      } else {
        console.log("ID no definido o no es un número");
      }
    });

    return {
      playlist,
    };
  },
};
