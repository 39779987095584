<template>
  <div class="">
    <div class="flex justify-center min-h-screen">
      <div
        class="hidden bg-cover lg:block lg:w-2/3"
        style="
          background-image: url(https://images.unsplash.com/photo-1616763355603-9755a640a287?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80);
        "
      >
        <div
          class="flex items-center h-full px-20 bg-gray-900 bg-opacity-40"
        ></div>
      </div>

      <div class="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
        <div class="flex-1 mt-4 mb-5">
          <div class="text-center">
            <img class="mx-auto h-40" src="../../assets/logo.png" alt="logo" />
            <p class="mt-1 text-letra-light dark:text-letra-dark">
              Sign in to access your account
            </p>
          </div>

          <div class="mt-8">
            <form @submit.prevent="handleSubmit">
              <div class="mb-4 flex justify-between">
                <div class="mb-0 mr-2 mb-0">
                  <label
                    class="block mb-2 text-sm text-letra-light dark:text-letra-dark"
                    for="firstName"
                  >
                    First Name
                  </label>
                  <input
                    v-model="firstName"
                    :class="{
                      'border-red-500 dark:border-red-500': errors.firstName,
                      'border-gray-200 dark:border-gray-700': !errors.firstName,
                    }"
                    @input="errors.firstName = null"
                    class="input-field block w-full font-light px-4 py-2 mt-2 text-letra-fields placeholder-gray-400 rounded-md dark:placeholder-letra-fieldsdark dark:text-gray-300 dark:border-letra-dark focus:border-blue-400 dark:border-opacity-50 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    id="firstName"
                    type="text"
                    placeholder="First Name"
                  />
                </div>
                <div class="ml-2">
                  <label
                    class="block mb-2 text-sm text-letra-light dark:text-letra-dark"
                    for="lastName"
                  >
                    Last Name
                  </label>
                  <input
                    v-model="lastName"
                    :class="{
                      'border-red-500 dark:border-red-500': errors.lastName,
                      'border-gray-200 dark:border-gray-700': !errors.lastName,
                    }"
                    @input="errors.lastName = null"
                    class="input-field block w-full font-light px-4 py-2 mt-2 text-letra-fields placeholder-gray-400 rounded-md dark:placeholder-letra-fieldsdark dark:text-gray-300 dark:border-letra-dark focus:border-blue-400 dark:border-opacity-50 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    id="lastName"
                    type="text"
                    placeholder="Last Name"
                  />
                </div>
              </div>

              <div class="mb-4">
                <label
                  class="block text-sm text-letra-light dark:text-letra-dark"
                  for="birthDate"
                >
                  Date of Birth
                </label>
                <div class="flex justify-between">
                  <div class="mr-2">
                    <input
                      v-model="birthDay"
                      :class="{
                        'border-red-500 dark:border-red-500': errors.birthDay,
                        'border-gray-200 dark:border-gray-700':
                          !errors.birthDay,
                      }"
                      @input="errors.birthDay = null"
                      class="input-field block w-full font-light px-4 py-2 mt-2 text-letra-fields placeholder-gray-400 rounded-md dark:placeholder-letra-fieldsdark dark:text-gray-300 dark:border-letra-dark focus:border-blue-400 dark:border-opacity-50 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                      id="dayOfBirth"
                      type="number"
                      placeholder="Día"
                      min="1"
                      max="31"
                    />
                  </div>
                  <div class="ml-2 mr-2">
                    <input
                      v-model="birthMonth"
                      :class="{
                        'border-red-500 dark:border-red-500': errors.birthMonth,
                        'border-gray-200 dark:border-gray-700':
                          !errors.birthMonth,
                      }"
                      @input="errors.birthMonth = null"
                      class="input-field block w-full font-light px-4 py-2 mt-2 text-letra-fields placeholder-gray-400 rounded-md dark:placeholder-letra-fieldsdark dark:text-gray-300 dark:border-letra-dark focus:border-blue-400 dark:border-opacity-50 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                      id="monthOfBirth"
                      type="number"
                      placeholder="Mes"
                      min="1"
                      max="12"
                    />
                  </div>
                  <div class="ml-2">
                    <input
                      v-model="birthYear"
                      :class="{
                        'border-red-500 dark:border-red-500': errors.birthYear,
                        'border-gray-200 dark:border-gray-700':
                          !errors.birthYear,
                      }"
                      @input="errors.birthYear = null"
                      class="input-field block w-full font-light px-4 py-2 mt-2 text-letra-fields placeholder-gray-400 rounded-md dark:placeholder-letra-fieldsdark dark:text-gray-300 dark:border-letra-dark focus:border-blue-400 dark:border-opacity-50 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                      id="yearOfBirth"
                      type="number"
                      placeholder="Año"
                      min="1900"
                    />
                  </div>
                </div>
              </div>

              <div>
                <label
                  for="email"
                  class="block mb-2 text-sm text-letra-light dark:text-letra-dark"
                  >Email Address</label
                >
                <input
                  v-model="email"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="example@example.com"
                  :class="{
                    'border-red-500 dark:border-red-500': errors.email,
                    'border-gray-200 dark:border-gray-700': !errors.email,
                  }"
                  @input="errors.email = null"
                  class="input-field block w-full font-light px-4 py-2 mt-2 text-letra-fields placeholder-gray-400 rounded-md dark:placeholder-letra-fieldsdark dark:text-gray-300 dark:border-letra-dark focus:border-blue-400 dark:border-opacity-50 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                />
                <div
                  v-if="errors.email"
                  class="mt-2 flex items-center space-x-2 text-red-500 text-sm"
                >
                  <i class="fas fa-exclamation-circle"></i>
                  <p>{{ errors.email }}</p>
                </div>
              </div>

              <div class="mt-6">
                <div class="flex justify-between mb-2"></div>

                <div class="relative">
                  <input
                    :type="showPassword ? 'text' : 'password'"
                    v-model="password"
                    name="password"
                    id="password"
                    placeholder="Your Password"
                    :class="{
                      'border-red-500 dark:border-red-500': errors.password,
                      'border-gray-200 dark:border-gray-700': !errors.password,
                    }"
                    @input="errors.password = null"
                    class="input-field block w-full font-light px-4 py-2 mt-2 text-letra-fields placeholder-gray-400 border border-gray-200 rounded-md dark:placeholder-letra-fieldsdark dark:bg-bg-modedark dark:text-gray-300 dark:border-letra-dark focus:border-blue-400 dark:border-opacity-50 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                  />

                  <div
                    class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-letra-light dark:text-letra-dark opacity-50"
                  >
                    <i
                      @click="showPassword = !showPassword"
                      :class="
                        showPassword
                          ? 'fas fa-eye-slash cursor-pointer'
                          : 'fas fa-eye cursor-pointer'
                      "
                    >
                    </i>
                  </div>
                </div>

                <div
                  v-if="errors.password"
                  class="mt-2 flex items-center space-x-2 text-red-500 text-sm"
                >
                  <i class="fas fa-exclamation-circle"></i>
                  <p>{{ errors.password }}</p>
                </div>
              </div>

              <div class="mt-4">
                <div class="flex justify-between mb-2">
                  <label
                    for="confirmPassword"
                    class="text-sm text-letra-light dark:text-letra-dark"
                    >Confirm Password</label
                  >
                </div>

                <div class="relative">
                  <input
                    :type="showConfirmPassword ? 'text' : 'password'"
                    v-model="confirmPassword"
                    name="confirmPassword"
                    id="confirmPassword"
                    placeholder="Confirm Your Password"
                    :class="{
                      'border-red-500 dark:border-red-500':
                        errors.confirmPassword,
                      'border-gray-200 dark:border-gray-700':
                        !errors.confirmPassword,
                    }"
                    @input="errors.confirmPassword = null"
                    class="input-field block w-full font-light px-4 py-2 mt-2 text-letra-fields placeholder-gray-400 rounded-md dark:placeholder-letra-fieldsdark dark:text-gray-300 dark:border-letra-dark focus:border-blue-400 dark:border-opacity-50 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                  />

                  <div
                    class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-letra-light dark:text-letra-dark opacity-50"
                  >
                    <i
                      @click="showConfirmPassword = !showConfirmPassword"
                      :class="
                        showConfirmPassword
                          ? 'fas fa-eye-slash cursor-pointer'
                          : 'fas fa-eye cursor-pointer'
                      "
                    >
                    </i>
                  </div>
                </div>

                <div
                  v-if="errors.confirmPassword"
                  class="mt-2 flex items-center space-x-2 text-red-500 text-sm"
                >
                  <i class="fas fa-exclamation-circle"></i>
                  <p>{{ errors.confirmPassword }}</p>
                </div>
              </div>

              <div class="mt-4">
                <label class="inline-flex items-center">
                  <input
                    type="checkbox"
                    v-model="termsAccepted"
                    :class="{
                      'border-red-500 dark:border-red-500':
                        errors.termsAccepted,
                      'border-gray-300 dark:border-gray-700':
                        !errors.termsAccepted,
                    }"
                    @change="errors.termsAccepted = null"
                    class="input-field text-logo-light focus:ring-0 focus:outline-none form-checkbox h-5 w-5 text-logo-light border-gray-300 dark:focus:ring-0 darkfocus:outline-none"
                  />
                  <span
                    class="ml-2 text-sm text-letra-light dark:text-letra-dark"
                    >By clicking, you agree to the
                    <router-link
                      to=""
                      class="text-logo-light focus:outline-none focus:underline hover:underline focus:text-logo-dark hover:text-logo-dark"
                      >terms and conditions</router-link
                    >.</span
                  >
                </label>
              </div>

              <div class="mt-4">
                <div
                  v-if="errors.missingFields"
                  class="flex items-center space-x-2 mb-2 text-red-500 text-sm"
                >
                  <i class="fas fa-exclamation-circle"></i>
                  <p>{{ errors.missingFields }}</p>
                </div>

                <button
                  class="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-logo-light rounded-md hover:bg-logo-dark focus:outline-none focus:bg-logo-dark focus:ring focus:ring-logo-dark focus:ring-opacity-50"
                >
                  Sign in
                </button>

                <div
                  v-if="errorMessage"
                  class="mt-2 flex items-center space-x-2 text-red-500 text-sm"
                >
                  <i class="fas fa-exclamation-circle"></i>
                  <p>{{ errorMessage }}</p>
                </div>
              </div>

              <div class="flex items-center my-6">
                <div
                  class="flex-grow border-t border-gray-300 dark:border-letra-dark"
                ></div>
                <span
                  class="px-2 dark:bg-gray-900 text-letra-light dark:text-letra-dark"
                >
                  Other Options
                </span>
                <div
                  class="flex-grow border-t border-gray-300 dark:border-letra-dark"
                ></div>
              </div>

              <div class="flex justify-center">
                <SocialLogin />
              </div>
            </form>

            <p
              class="mt-6 text-sm text-center text-letra-light dark:text-letra-dark"
            >
              Already have an account?
              <router-link
                to="/login"
                class="text-logo-light focus:outline-none focus:underline hover:underline focus:text-logo-dark hover:text-logo-dark"
                >Sign in</router-link
              >.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import SocialLogin from "@/components/auth/SocialLogin.vue";

export default {
  name: "RegisterView",
  components: {
    SocialLogin,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    return { router, store };
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      birthDay: "",
      birthMonth: "",
      birthYear: "",
      termsAccepted: false,
      errors: {},
      errorMessage: "",
      showPassword: false,
      showConfirmPassword: false,
    };
  },
  methods: {
    validateForm() {
      this.errors = {};
      let missingFields = [];

      if (!this.firstName) {
        this.errors.firstName = "Por favor, introduce tu nombre.";
        missingFields.push("nombre");
      }

      if (!this.lastName) {
        this.errors.lastName = "Por favor, introduce tu apellido.";
        missingFields.push("apellido");
      }

      if (!this.email) {
        this.errors.email = "Por favor, introduce tu correo electrónico.";
      } else if (!/\S+@\S+\.\S+/.test(this.email)) {
        this.errors.email =
          "Por favor, introduce un correo electrónico válido.";
      }

      if (!this.password) {
        this.errors.password = "Por favor, introduce tu contraseña.";
      } else if (
        !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
          this.password
        )
      ) {
        this.errors.password =
          "La contraseña debe tener al menos 8 caracteres, incluyendo una letra mayúscula, una minúscula, un número y un carácter especial.";
      }

      if (this.password !== this.confirmPassword) {
        this.errors.confirmPassword = "Las contraseñas no coinciden.";
      }

      if (!this.birthDay || !this.birthMonth || !this.birthYear) {
        this.errors.birthDay = "Por favor, introduce el dia.";
        this.errors.birthMonth = "Por favor, introduce el mes.";
        this.errors.birthYear = "Por favor, introduce el año.";
        missingFields.push("fecha de nacimiento");
      }

      if (!this.termsAccepted) {
        missingFields.push("términos y condiciones");
        this.errors.termsAccepted = "Terminos no acceptados.";
      }

      if (missingFields.length > 0) {
        this.errors.missingFields =
          "Faltan datos en los siguientes campos: " +
          missingFields.join(", ") +
          ".";
      }

      return Object.keys(this.errors).length === 0;
    },

    async handleSubmit() {
      if (!this.validateForm()) {
        return;
      }

      try {
        const response = await axios.post(
          "https://wavesmusic.app/api/users/createUser",
          {
            register: {
              nickname: `${this.firstName}_${this.lastName}`,
              name: this.firstName,
              last_name: this.lastName,
              email: this.email,
              password: this.password,
              edad: `${this.birthYear}-${this.birthMonth}-${this.birthDay}`,
            },
            language: "en-GB",
          }
        );

        if (response.data.result) {
          const token = response.data.token;
          const user = response.data.user;
          localStorage.setItem("userToken", token);
          console.log(token);
          localStorage.setItem("userData", JSON.stringify(user)); // Los datos deben ser convertidos a una cadena para ser almacenados
          console.log(user);

          this.$store.commit("setToken", token);
          this.$store.commit("setUser", user);


          this.router.push("/login");

          // Clear form
          this.firstName = "";
          this.lastName = "";
          this.email = "";
          this.password = "";
          this.confirmPassword = "";
          this.birthDay = "";
          this.birthMonth = "";
          this.birthYear = "";
          this.termsAccepted = false;
        } else if (response.data.error) {
          this.errorMessage = response.data.error;
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data.error === "El usuario ya existe"
        ) {
          this.errorMessage =
            "El usuario ya existe. Por favor, elige un correo electrónico diferente.";
        } else {
          this.errorMessage =
            "Hubo un error al crear la cuenta. Por favor, inténtalo de nuevo.";
        }
        console.error(error);
      }
    },
  },
};
</script>

<style></style>