import axios from 'axios';
import { ActionTree, MutationTree } from 'vuex';
import { RootState, Song } from '../types/types';

export interface HistorialItem {
  Id?: number;
  cancionId?: number;
  albumId?: number;
  playlistId?: number;
  type: string;
  Song?: Song;
}

export interface HistorialState {
  items: HistorialItem[];
  historySongs: Song[];
  playingAllHistory: boolean;
}

const state: HistorialState = {
  items: [],
  historySongs: [],
  playingAllHistory: false,

};

const mutations: MutationTree<HistorialState> = {
  setHistorialItems(state, items: HistorialItem[]) {
    state.items = items;
  },
  addToHistorial(state, item: HistorialItem) {
    state.items.push(item);
  },
  setHistorySongs(state, songs: Song[]) {
    state.historySongs = songs;
  },
  SET_PLAYING_ALL_HISTORY(state, payload) {
    state.playingAllHistory = payload;
  },
  
};

const actions: ActionTree<HistorialState, RootState> = {
  async fetchHistorySongs({ commit }, usuarioId: string) {
    try {
      
      const response = await axios.get(`https://wavesmusic.app/api/historial/songs/${usuarioId}`);
      
      const historySongs = response.data.map((relation: any) => {
        const song = relation.Song;
        return {
          name: song.title,
          artist: song.artist,
          duration: song.duration,
          release_date: song.release_date,
          genre: song.genre,
          src: song.audio_file_url,
          cover: song.album_cover_url,
          album: song.album,
          Id: song.Id
        };
      });
      commit('setHistorySongs', historySongs);
    } catch (error) {
      console.error("Error fetching history songs:", error);
    }
  },
  async addToHistorialSongs({ commit, rootState }, song: Song) {
    try {
      const usuarioId = rootState.user.user?.id;
      const cancionId = song.Id;
      const response = await axios.post(`https://wavesmusic.app/api/historial/song/${usuarioId}/${cancionId}`);
      commit('addToHistorial', response.data);
    } catch (error) {
      console.error('Error agregando canción al historial:', error);
    }
  },
  playAllHistorySongs({ commit, state }) {
    if (state.historySongs && state.historySongs.length > 0) {
      // Aquí tienes que llamar a los métodos de mutación adecuados para cambiar el playlist actual, la canción actual, y empezar la reproducción
      commit('setPlaylist', state.historySongs);
      commit('setCurrentSong', state.historySongs[0]);
      commit('setPlaying', true);
      commit('SET_PLAYING_ALL_HISTORY', true);

    }
  },
};

export default {
  state,
  mutations,
  actions,
};