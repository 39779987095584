import Vuex from 'vuex';

import player from './modules/player';
import artist from './modules/artist';
import user from './modules/user';
import historial from './modules/historial';
import favoritos from './modules/favoritos';
import search from './modules/search';
import frecuencias from './modules/frecuencias';
import playlist from './modules/playlist';

export default new Vuex.Store({
  modules: {
    artist,
    player,
    user,
    historial,
    favoritos,
    search,
    frecuencias,
    playlist,
  },
  
});