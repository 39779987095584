import axios from "axios";
import { MutationTree, ActionTree } from "vuex";
import { Artist, Album, RootState, Song, Playlist } from "../types/types";
import { User } from "./user";

export interface SearchResult {
  users: User[];
  artists: Artist[];
  albums: Album[];
  songs: Song[];
  playlists: Playlist[];
}

export interface SearchState {
  query: string | null;
  results: SearchResult | null;
  resultsPlaylistUser: Playlist[] | null;
}

const state: SearchState = {
  query: null,
  results: null,
  resultsPlaylistUser: null,
};

const mutations: MutationTree<SearchState> = {
  setQuery(state, query: string) {
    state.query = query;
  },
  setResults(state, results: SearchResult) {
    state.results = results;
  },
  setResultsPlaylistUser(state, resultsPlaylistUser: Playlist[]) {
    state.resultsPlaylistUser = resultsPlaylistUser;
  },
};

const actions: ActionTree<SearchState, RootState> = {
  async fetchResults({ commit }, query: string | null | undefined) {
    const token = localStorage.getItem("userToken");
    console.log(token);
  
    if (!token) {
      console.error("fetchResults fue llamado sin un token");
      return;
    }
    if (query === null || query === undefined) {
      console.error("fetchResults fue llamado con un query inválido:", query);
      return;
    }
    try {
      const response = await axios.get(
        `https://wavesmusic.app/api/searchHistory/search?q=${query}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Respuesta:", response.data); // imprime la respuesta en la consola
  
      // Mapeo de la respuesta de la API
      const apiSongs = response.data.songs;
      const songs = apiSongs.map((song: { Id: any; title: any; artist: any; audio_file_url: any; album_cover_url: any; type: any; artistId: any; }) => ({
        Id: song.Id,
        name: song.title,
        artist: song.artist,
        src: song.audio_file_url,
        cover: song.album_cover_url,
        type: song.type,
        artistId: song.artistId,
      }));
  
      const results: SearchResult = {
        ...response.data,
        songs,
      };
      console.log(results);
      commit("setResults", results);
    } catch (error) {
      console.error(error);
    }
  },
  
  async fetchResultsPlaylistUser({ commit }, query: string | null | undefined) {
    const token = localStorage.getItem("userToken");
    console.log(token);

    if (!token) {
      console.error("fetchResultsPlaylistUser fue llamado sin un token");
      return;
    }
    if (query === null || query === undefined) {
      console.error(
        "fetchResultsPlaylistUser fue llamado con un query inválido:",
        query
      );
      return;
    }
    try {
      const response = await axios.get(
        `https://wavesmusic.app/api/searchHistory/searchPlaylist?q=${query}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resultsPlaylistUser: Playlist[] = response.data.playlists;
      console.log(resultsPlaylistUser);
      commit("setResultsPlaylistUser", resultsPlaylistUser);
    } catch (error) {
      console.error(error);
    }
  },
};

export default {
  state,
  mutations,
  actions,
};
