<template>
    <div class="flex justify-center">
      <div class="logo-container mx-2">
        <button @click="loginWithGoogle">
          <img src="../../assets/google.png" alt="Google" />
        </button>
      </div>
      <div class="logo-container mx-2">
        <button @click="loginWithFacebook">
          <img src="../../assets/meta.svg" alt="Meta" class="w-14" />
        </button>
      </div>
      <div class="logo-container mx-2">
        <button @click="loginWithTwitch">
          <img src="../../assets/twitch2.svg" alt="Twitch" class="w-14" />
        </button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      async loginWithGoogle() {
        window.open("http://localhost:5000/users/auth/google/callback", "Google Login", "width=500,height=600,menubar=no,toolbar=no,location=no");
      },
      async loginWithFacebook() {
        window.open(
          "http://localhost:5000/users/auth/facebook/callback",
          "Meta Login", "width=500,height=600,menubar=no,toolbar=no,location=no"
        );
      },
      async loginWithTwitch() {
        window.open("http://localhost:5000/users/auth/twitchtv/callback", "Twitchtv Login", "width=500,height=600,menubar=no,toolbar=no,location=no");
      },
    },
    
  };
  </script>