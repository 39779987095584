
import myCollectionNav from '@/components/myCollection/myCollectionNav.vue'
import myCollectionAlbumsComponent from '@/components/myCollection/Albums/myCollectionAlbumsComponent.vue'

export default {
  components: {
 myCollectionNav,
 myCollectionAlbumsComponent,
},
}

