
import myCollectionNav from '@/components/myCollection/myCollectionNav.vue'
import myCollectionHistorialComponent from '@/components/myCollection/Historial/myCollectionHistorialComponent.vue'

export default {
  components: {
 myCollectionNav,
 myCollectionHistorialComponent,
},
}

