<template>
  <div class=" ">
    <div class="flex justify-center min-h-screen">
      <div
        class="hidden bg-cover lg:block lg:w-2/3"
        style="
          background-image: url(https://images.unsplash.com/photo-1616763355603-9755a640a287?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80);
        "
      >
        <div
          class="flex items-center h-full px-20 bg-gray-900 bg-opacity-40"
        ></div>
      </div>

      <div class="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
        <div class="flex-1 mt-4 mb-5">
          <div class="text-center">
            <img class="mx-auto h-40" src="../../assets/logo.png" alt="logo" />
            <p class="mt-1 text-letra-light dark:text-letra-dark">
              Sign in to access your account
            </p>
          </div>

          <div class="mt-8">
            <form @submit.prevent="login">
              <div>
                <label
                  for="email"
                  class="block mb-2 text-sm text-letra-light dark:text-letra-dark"
                  >Email Address</label
                >
                <input
                  type="email"
                  v-model="email"
                  name="email"
                  id="email"
                  placeholder="example@example.com"
                  :class="{
                    'border-red-500 dark:border-red-500': errors.email,
                    'border-gray-200 dark:border-gray-700': !errors.email,
                  }"
                  @input="errors.email = null"
                  class="input-field block w-full font-light px-4 py-2 mt-2 text-letra-fields placeholder-gray-400 border border-gray-200 rounded-md dark:placeholder-letra-fieldsdark dark:text-letra-fieldsdark dark:border-letra-dark focus:border-blue-400 dark:border-opacity-50 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                />

                <div
                  v-if="errors.email"
                  class="mt-2 flex items-center space-x-2 text-red-500 text-sm"
                >
                  <i class="fas fa-exclamation-circle"></i>
                  <p>{{ errors.email }}</p>
                </div>
              </div>

              <div class="mt-6">
                <div class="flex justify-between mb-2">
                  <label
                    for="password"
                    class="text-sm text-letra-light dark:text-letra-dark"
                    >Password</label
                  >
                  <a
                    href="/forgot"
                    class="text-sm text-logo-light focus:text-logo-dark hover:text-logo-dark hover:underline"
                    >Forgot password?</a
                  >
                </div>

                <div class="relative">
                  <input
                    :type="showPassword ? 'text' : 'password'"
                    v-model="password"
                    name="password"
                    id="password"
                    placeholder="Your Password"
                    :class="{
                      'border-red-500 dark:border-red-500': errors.password,
                      'border-gray-200 dark:border-gray-700': !errors.password,
                    }"
                    @input="errors.password = null"
                    class="input-field block w-full font-light px-4 py-2 mt-2 text-letra-fields placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-letra-fieldsdark dark:bg-bg-modedark dark:text-gray-300 dark:border-letra-dark focus:border-blue-400 dark:border-opacity-50 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                  />

                  <div
                    class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-letra-light dark:text-letra-dark opacity-50"
                  >
                    <i
                      @click="showPassword = !showPassword"
                      :class="
                        showPassword
                          ? 'fas fa-eye-slash cursor-pointer'
                          : 'fas fa-eye cursor-pointer'
                      "
                    >
                    </i>
                  </div>
                </div>

                <div
                  v-if="errors.password"
                  class="mt-2 flex items-center space-x-2 text-red-500 text-sm"
                >
                  <i class="fas fa-exclamation-circle"></i>
                  <p>{{ errors.password }}</p>
                </div>
              </div>

              <div class="mt-4">
                <label class="inline-flex items-center">
                  <input
                    v-model="rememberMe"
                    type="checkbox"
                    class="input-field focus:border-nonetext-logo-light focus:ring-0 focus:outline-none form-checkbox h-5 w-5 text-logo-light border-gray-300 dark:focus:ring-0 darkfocus:outline-none"
                  />
                  <span
                    class="ml-2 text-sm text-letra-light dark:text-letra-dark"
                    >Remember Me</span
                  >
                </label>
              </div>

              <div class="mt-4">
                <button
                  class="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-logo-light rounded-md hover:bg-logo-dark focus:outline-none focus:bg-logo-dark focus:ring focus:ring-logo-dark focus:ring-opacity-50"
                >
                  Sign in
                </button>
              </div>

              <div class="mt-4 flex justify-center">
                <div
                  v-if="errors.length"
                  class="mt-2 flex items-center space-x-2 text-red-500 text-sm"
                >
                  <i class="fas fa-exclamation-circle"></i>
                  <ul>
                    <li v-for="(error, index) in errors" :key="index">
                      {{ error }}
                    </li>
                  </ul>
                </div>
              </div>

              <div class="flex items-center my-6">
                <div
                  class="flex-grow border-t border-gray-300 dark:border-letra-dark"
                ></div>
                <span
                  class="px-2 dark:bg-gray-900 text-letra-light dark:text-letra-dark"
                >
                  Other Options
                </span>
                <div
                  class="flex-grow border-t border-gray-300 dark:border-letra-dark"
                ></div>
              </div>

              <div class="flex justify-center">
                <SocialLogin />
              </div>
            </form>

            <p
              class="mt-6 text-sm text-center text-letra-light dark:text-letra-dark"
            >
              Don&#x27;t have an account yet?
              <router-link
                to="/signup"
                class="text-logo-light focus:outline-none focus:underline hover:underline focus:text-logo-dark hover:text-logo-dark"
                >Sign up</router-link
              >.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useStore } from "vuex";
import SocialLogin from "@/components/auth/SocialLogin.vue";

export default {
  data() {
    return {
      email: "",
      password: "",
      rememberMe: false,
      errors: {},
      showPassword: false,
      loading: false,
    };
  },
  methods: {
    validateForm() {
      this.errors = {};

      if (!this.email) {
        this.errors.email = "Por favor, introduce tu correo electrónico.";
      } else if (!/\S+@\S+\.\S+/.test(this.email)) {
        this.errors.email =
          "Por favor, introduce un correo electrónico válido.";
      }

      if (!this.password) {
        this.errors.password = "Por favor, introduce tu contraseña.";
      }

      return Object.keys(this.errors).length === 0;
    },
    async login() {
      if (!this.validateForm()) {
        return;
      }

      this.loading = true;

      try {
        const response = await axios.post(
          "https://wavesmusic.app/api/users/login",
          {
            email: this.email,
            password: this.password,
          }
        );

        this.loading = false;

        if (response.status === 200) {
          console.log("Se ha iniciado la sesión correctamente.");

          if (this.rememberMe) {
            localStorage.setItem("email", this.email);
          }
          const token = response.data.token;
          const user = response.data.user;
          localStorage.setItem("userToken", token);
          console.log(token);
          localStorage.setItem("user", JSON.stringify(user)); // Los datos deben ser convertidos a una cadena para ser almacenados
          console.log(user);

          this.$store.commit("setToken", token);
          this.$store.commit("setUser", user);


          // Esto está comentado por ahora, descomentar cuando este creado la vista home

          this.$router.push({ name: "myCollection/mixes" });
        } else {
          this.errors.form = "Algo ha ido mal, por favor intenta de nuevo.";
        }
      } catch (error) {
        this.loading = false;

        if (error.response && error.response.status === 401) {
          if (error.response.data === "Usuario no registrado") {
            this.errors.email = "Correo electrónico no registrado.";
          } else if (error.response.data === "Credenciales inválidas") {
            this.errors.password = "Contraseña incorrecta.";
          }
        } else {
          this.errors.form = "Ocurrió un error al iniciar sesión";
        }
      }
    },
  },
  setup() {
    const store = useStore();
    return { store };
  },
  created() {
    const rememberedEmail = localStorage.getItem("email");
    if (rememberedEmail) {
      this.email = rememberedEmail;
    }
  },

  components: {
    SocialLogin,
  },
};
</script>

<style scoped></style>