
import { defineComponent, onMounted, ref, Ref, watch, toRef,  } from 'vue';
import { gsap } from 'gsap';
import { useStorage } from '@vueuse/core'

export default defineComponent({
  props: ['song', 'playing', 'shuffle', 'repeat'],
  emits: ['closeScreen','toggleShuffle','toggleRepeat'],
  setup(props, { emit }) {
    const song = toRef(props, 'song');
    const playing = toRef(props, 'playing');
    const isLightMode: Ref<boolean> = useStorage('is-light-mode', false); 
      const toggleShuffle = () => {
  emit('toggleShuffle');
};

const toggleRepeat = () => {
  emit('toggleRepeat');
};

    const coverImage: Ref<HTMLElement | null> = ref(null);
    let shadowAnimation = gsap.timeline();
    let shadowConfig = { blur: 0, spread: 0 };

    const startShadowAnimation = () => {
      if(shadowAnimation) {
        shadowAnimation.kill();
        shadowAnimation = gsap.timeline();
      }

      shadowAnimation.add(gsap.to(shadowConfig, {
        blur: 50,
        spread: 50,
        repeat: -1,
        yoyo: true,
        ease: 'power1.inOut',
        onUpdate: () => {
          if (coverImage.value) {
            coverImage.value.style.boxShadow = `0px 0px ${shadowConfig.blur}px ${shadowConfig.spread}px rgba(255,255,255,0.25)`;
          }
        },
        duration: 0.8
      }));

      if (!playing.value) {
        shadowAnimation.pause();
      }
    };

    const closeScreen = () => {
      emit('closeScreen');
    };

    watch(() => props.playing, (newPlaying) => {
      if(!newPlaying){
        shadowAnimation.pause();
      } else {
        shadowAnimation.resume();
      }
    })

    watch(song, () => {
      if (coverImage.value) {
        const img = new Image();
        img.onload = () => {
          startShadowAnimation();
        };
        img.src = song.value.cover;
      }
    })

    onMounted(() => {
      if (coverImage.value && playing.value) {
        const img = new Image();
        img.onload = () => {
          startShadowAnimation();
        };
        img.src = song.value.cover;
      }
    });

    return {
      closeScreen,
      coverImage,
      isLightMode ,
      toggleShuffle,
  toggleRepeat,
    };
  },
});
