
import myCollectionNav from '@/components/myCollection/myCollectionNav.vue'
import myCollectionPlaylistsComponent from '@/components/myCollection/Mixes&Playlists/myCollectionPlaylistsComponent.vue'

export default {
  components: {
 myCollectionNav,
 myCollectionPlaylistsComponent,
},
}

