// favoritos.ts
import axios from 'axios';
import { MutationTree, ActionTree } from 'vuex';
import { RootState } from '../types/types'; 
import { Song } from '../types/types';
import { Album } from '../types/types';
import { Artist } from '../types/types';
import { Playlist } from '../types/types';

export interface FavoritesState {
  favoriteSongs: Song[];
  favoriteAlbums: Album[];
  favoriteArtists: Artist[];
  favoritePlaylists: Playlist[];
}

const state: FavoritesState = {
  favoriteSongs: [],
  favoriteAlbums: [],
  favoriteArtists: [],
  favoritePlaylists: [], 
};

const mutations: MutationTree<FavoritesState> = {
  setFavoriteSongs(state, songs: Song[]) {
    state.favoriteSongs = songs;
  },
  setFavoriteAlbums(state, albums: Album[]) { 
    state.favoriteAlbums = albums;
  },
  setFavoriteArtists(state, artists: Artist[]) {
    state.favoriteArtists = artists;
  },
  setFavoritePlaylists(state, playlists: Playlist[]) {
    state.favoritePlaylists = playlists;
  },
};
const actions: ActionTree<FavoritesState, RootState> = {
    async fetchFavoriteSongs({ commit }, usuarioId: string) {
      try {
        const response = await axios.get(`https://wavesmusic.app/api/favoritos/${usuarioId}/favoritos/canciones`);
        const favoriteSongs = response.data.map((relation: any) => {
          const song = relation.Song;
          return {
            name: song.title, 
            artist: song.artist,  
            src: song.audio_file_url, 
            cover: song.album_cover_url, 
            Id: song.Id
          };
        });
        commit('setFavoriteSongs', favoriteSongs);
      } catch (error) {
        console.error("Error fetching favorite songs:", error);
      }
    },
    async fetchFavoriteAlbums({ commit }, usuarioId: string) {
      try {
        const response = await axios.get(`https://wavesmusic.app/api/favoritos/${usuarioId}/favoritos/albums`);
        const favoriteAlbums = response.data.map((relation: any) => {
          const album = relation.Album;
          const songs = album.Songs.map((song: any) => ({
            name: song.title, 
            artist: song.artist,  
            src: song.audio_file_url, 
            cover: song.album_cover_url, 
            Id: song.Id
          }));
          return {
            id: album.id,
            title: album.title,
            release_date: album.release_date,
            cover_image_url: album.cover_image_url,
            artists_id: album.artists_id,
            type: album.type,
            Songs: songs,
          };
        });
        commit('setFavoriteAlbums', favoriteAlbums);
      } catch (error) {
        console.error("Error fetching favorite albums:", error);
      }
    },
    // Acción para obtener los artistas favoritos del usuario
async fetchFavoriteArtists({ commit }, usuarioId: string) {
  try {
    // Hacer la solicitud a la URL que maneja los artistas favoritos
    const response = await axios.get(`https://wavesmusic.app/api/seguimArtists/following/${usuarioId}`);
    const userData = response.data;

    if (!userData || userData.length === 0 || !userData[0].Artists) {
      console.error("No se encontraron artistas en la respuesta de la API.");
      return;
    }

    // Extraer el campo Artists que contiene los datos de los artistas
    const artistsData = userData[0].Artists;

    // Mapear la respuesta de la API a tu estructura de datos
    const favoriteArtists = artistsData.map((artist: any) => {
      return {
        id: artist.Id,
        name: artist.name,
        photo_url: artist.photo_url,
        // ...otros campos que necesites
      };
    });

    // Hacer commit de los datos al estado
    commit('setFavoriteArtists', favoriteArtists);
  } catch (error) {
    console.error("Error al obtener los artistas favoritos:", error);
  }
},

async fetchFavoritePlaylists({ commit }, usuarioId: string) {
  try {
    const response = await axios.get(`https://wavesmusic.app/api/favoritos/${usuarioId}/favoritos/playlists`);
    const favoritePlaylists = response.data.map((relation: any) => {
      const playlist = relation.Playlist;
      const songs = playlist.Songs.map((song: any) => ({
        name: song.title, 
        artist: song.artist,  
        src: song.audio_file_url, 
        cover: song.album_cover_url, 
        Id: song.Id
      }));
      return {
        id: playlist.Id,
        name: playlist.name,
        description: playlist.description,
        cover_file_url: playlist.cover_file_url,
        Songs: songs,
      
      };
    });
    commit('setFavoritePlaylists', favoritePlaylists);
  } catch (error) {
    console.error("Error fetching favorite playlists:", error);
  }
},
    
};

  

export default {
  state,
  mutations,
  actions,
};