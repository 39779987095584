
import { defineComponent, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { Song } from "@/store/types/types";

export default defineComponent({
  setup() {
    const store = useStore();
    const isPlayingAll = ref(false);
    const isPlaying = computed(() => store.state.player.playing);
    const currentSong = computed(() => store.state.player.currentSong);
    const songs = computed(() => store.state.playlist.playlist.Songs);
    const hoveredIndex = ref(-1);
    const isMobile = ref(window.innerWidth <= 768);

    watch([isPlaying, currentSong], ([playing, song]) => {
      isPlayingAll.value = playing && songs.value?.some((s: Song) => s.Id === song.Id);
    });


const playSong = (song: Song, event: Event) => {
  event.preventDefault();
  event.stopPropagation();
  // Encuentra la URL de la canción y otros detalles necesarios.

  console.log("playSong", song);
  console.log("Intentando reproducir la canción: ", store.state.currentSong);
  // Verifica si la canción actual está en reproducción y si es la misma canción.
  if (isPlaying.value && currentSong.value?.Id === song.Id) {
    store.dispatch('togglePlay');
  } else {
    store.dispatch('playSong', song);
  }
  console.log("Intentando reproducir la canción: ", store.state.currentSong);
};

    const playAllSongs = () => {
      console.log("playAllSongsIN",store.state.playlist.playlist);
      if (!isPlaying.value) {
        store.dispatch('playAllSongs', store.state.playlist.playlist);
        isPlayingAll.value = true;
      } else {
        store.dispatch('togglePlay');
      }
    };
    function formatDate(dateStr: string | number | Date) {
      const date = new Date(dateStr);
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")}`;
    }

    window.addEventListener('resize', () => {
      isMobile.value = window.innerWidth <= 768;
    });

    return {
      isPlaying,
      currentSong,
      playSong,
      isPlayingAll,
      playAllSongs,
      hoveredIndex,
      isMobile,
      songs,
      formatDate
    };
  },
});
