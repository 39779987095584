
import { defineComponent, ref, computed, onMounted } from "vue"; // Importar onMounted
import axios from "axios";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import playlist from "@/store/modules/playlist";

export default defineComponent({
  name: "PlaylistBanner",

  setup() {
    const store = useStore();
    const playlist = computed(() => store.state.playlist.playlist);
    const route = useRoute();
    console.log("playlist setup", playlist.value);
    const userString = localStorage.getItem("user");
    const userObject = JSON.parse(userString || "{}");
    const userId = userObject.id;
    const isFollowing = ref(false);
    // Llamada a fetchArtist cuando el componente se monta
    onMounted(async () => {
      console.log("route.params:", route.params);

      const id = Number(route.params.id);
      console.log("onMounted: Llamando a fetchPlaylist con ID:", id);

      if (id) {
        await store.dispatch("fetchPlaylist", id);
      } else {
        console.log("ID no definido o no es un número");
      }
    });

    const toggleFollow = async () => {
      const artistId = route.params.id;
      try {
        if (isFollowing.value) {
          await axios.post(
            "https://wavesmusic.app/api/seguimArtists/unfollow",
            { usuarioId: userId, artistId: artistId }
          );
          isFollowing.value = false;
        } else {
          await axios.post("https://wavesmusic.app/api/seguimArtists/follow", {
            usuarioId: userId,
            artistId: artistId,
          });
          isFollowing.value = true;
        }
      } catch (error) {
        console.error("Error al seguir/dejar de seguir al artista:", error);
      }
    };

    return {
      playlist, // Esto es lo que devuelve el valor computado del artista desde el store
      isFollowing,
      toggleFollow,
    };
  },
});
