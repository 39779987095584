
import { defineComponent, computed, ref, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { Album } from '../../../store/types/types';

export default defineComponent({
  setup() {
    const store = useStore();

    // Nuevo ref para llevar un registro de cuántos álbumes mostrar
    const albumsToShow = ref(window.innerWidth <= 500 ? 4 : 5);

    const isPlaying = computed(() => store.state.player.playing);
    const currentAlbum = computed(() => store.state.player.currentAlbum);
    const favoriteAlbums = computed(() => store.state.favoritos.favoriteAlbums);
    const playAlbum = (album: Album) => {
  // Si el álbum seleccionado ya está en reproducción, solo alternamos el estado de play/pausa
  if (currentAlbum.value === album.id) {
      store.dispatch('togglePlay');
  } else {
      // Si es un álbum diferente o el álbum seleccionado aún no se ha reproducido, entonces cargamos el álbum y lo reproducimos
      store.dispatch('playAlbum', album);
  }
};


    const updateWidth = () => {
      if (window.innerWidth <= 500) {
        albumsToShow.value = 4;
      } else {
        albumsToShow.value = 5;
      }
    }

    const showMoreAlbums = () => {
      if (window.innerWidth <= 500) {
        albumsToShow.value += 4;
      } else {
        albumsToShow.value += 5;
      }
    }

    const groupedAlbums = computed(() => {
  let groups: Album[][] = []; // <-- Aquí se proporciona explícitamente el tipo.
  let totalGroupsToShow = Math.ceil(albumsToShow.value / (window.innerWidth <= 500 ? 4 : 5));

  for (let i = 0; i < totalGroupsToShow; i++) {
      let start = i * (window.innerWidth <= 500 ? 4 : 5);
      let end = start + (window.innerWidth <= 500 ? 4 : 5);
      groups.push(favoriteAlbums.value.slice(start, end));
  }

  return groups;
});


    onMounted(() => {
      window.addEventListener('resize', updateWidth);
      // Obtener los álbumes favoritos
      const userString = localStorage.getItem('user');
      const userObject = JSON.parse(userString || '{}');
      const userId = userObject.id;

      if (userId) {
        store.dispatch('fetchFavoriteAlbums', userId); // Este es el método que aún no has implementado
      } else {
        console.error('UserId no encontrado en el localStorage');
      }

      updateWidth();
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateWidth);
    });

    return {
      isPlaying,
      currentAlbum,
      playAlbum,
      showMoreAlbums,
      albumsToShow,
      groupedAlbums
    };
  }
});
