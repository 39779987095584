
import {  onMounted, onUnmounted } from 'vue'
import { useStorage } from '@vueuse/core'

export default {
  name: 'DarkModeSwitch',
  setup() {
    const isLightMode = useStorage('is-light-mode', false)

    const toggleDarkMode = () => {
      isLightMode.value = !isLightMode.value
      document.documentElement.classList.toggle('light-mode', isLightMode.value)
    }

    onMounted(() => {
      document.documentElement.classList.toggle('light-mode', isLightMode.value)
    })

    onUnmounted(() => {
      document.documentElement.classList.remove('light-mode')
    })

    return {
      isLightMode,
      toggleDarkMode
    }
  }
}
