<template>
  <div class="">
    <div class="flex justify-center min-h-screen">
      
      <div class="hidden bg-cover lg:block lg:w-2/3" style="background-image: url(https://images.unsplash.com/photo-1616763355603-9755a640a287?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80)">
        <div class="flex items-center h-full px-20 bg-gray-900 bg-opacity-40"></div>
      </div>
      
      <div class="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
        <div class="flex-1 mt-4 mb-6">
          
          <div class="text-center">
            <img class="mx-auto h-40" src="../../assets/logo.png" alt="logo">
            <p class="mt-1 text-letra-light dark:text-letra-dark">Forgot your password</p>
          </div>

          <p class="mt-10 text-sm text-center text-letra-light dark:text-letra-dark whitespace-nowrap overflow-hidden overflow-ellipsis">
            Enter your email and we'll send you a link to reset your password.
          </p>

          <div class="mt-4">
            <label for="email" class="block mb-2 text-sm text-letra-light dark:text-letra-dark">Email Address</label>
            <input v-model="email" type="email" name="email" id="email" placeholder="example@example.com" 
                   :class="{'border-red-500 dark:border-red-500': errors.email, 'border-gray-200 dark:border-gray-700': !errors.email}"
                   @input="errors.email = null" 
                   class=" input-field block w-full font-light px-4 py-2 mt-2 text-letra-fields placeholder-gray-400  border border-gray-200 rounded-md dark:placeholder-letra-fieldsdark  dark:text-letra-fieldsdark dark:border-letra-dark focus:border-blue-400 dark:border-opacity-50 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
            
            <div v-if="errors.email" class="mt-2 flex items-center space-x-2 text-red-500 text-sm">
              <i class="fas fa-exclamation-circle"></i>
              <p>{{ errors.email }}</p>
            </div>
          </div>

          <div class="mt-4">
            <button @click="sendResetPasswordRequest"
                    class="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-logo-light rounded-md hover:bg-logo-dark focus:outline-none focus:bg-logo-dark focus:ring focus:ring-logo-dark focus:ring-opacity-50">
              Send
            </button>
          </div>

          <div class="mt-4">
            <div v-if="message.type === 'success'" class="mt-2 flex items-center space-x-2 text-green-500 text-sm">
              <i class="fas fa-check-circle"></i>
              <p>{{ message.text }}</p>
            </div>

            <div v-if="message.type === 'error'" class="mt-2 flex items-center space-x-2 text-red-500 text-sm">
              <i class="fas fa-exclamation-circle"></i>
              <p>{{ message.text }}</p>
            </div>
          </div>        
          
          <p class="mt-6 text-sm text-center text-letra-light dark:text-letra-dark">
            If you don't receive the email, check your spam or contact <span class="text-logo-light">support@wavesmusic.com</span> for help.
          </p>

          <p class="mt-6 text-sm text-center text-letra-light dark:text-letra-dark">
            Go back to <router-link to="/login" class="text-logo-light focus:outline-none focus:underline hover:underline focus:text-logo-dark hover:text-logo-dark">Log in</router-link>.
          </p>
          
        </div>    
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  name: 'ForgotView',
  data() {
    return {
      email: '',
      message: {
        type: '',
        text: ''
      },
      errors: {
        email: null
      }
    }
  },
  methods: {
    validateForm() {
      let isValid = true;

      if (!this.email) {
        this.errors.email = 'Email is required';
        isValid = false;
      } else {
        this.errors.email = null;
      }

      return isValid;
    },
    async sendResetPasswordRequest() {
      if (!this.validateForm()) {
        return;
      }

      try {
        await axios.put('https://wavesmusic.app/api/users/forgotPassword', { 
          email: this.email, 
          code: '', 
          language: 'en-GB' 
        });

        this.message.type = 'success';
        this.message.text = 'Password reset email sent';
      } catch (error) {
        this.message.type = 'error';
        this.message.text = 'There was an error sending the password reset email'; 
      }
    }
  }
}
</script>