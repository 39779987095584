import axios from 'axios';
import { MutationTree, ActionTree } from 'vuex';
import { RootState } from '../types/types'; 
import { Song } from '../types/types';
import { ApiSong } from '../types/types';
import { Artist } from '../types/types';
import { Album } from '../types/types';

export interface ArtistState {
  artist: Artist | null;
  songs: Song[];
  albums: Album[];
 

}

const state: ArtistState = {
  artist: null,
  songs: [],
  albums: [],

};

const mutations: MutationTree<ArtistState> = {
  setArtist(state, artist: Artist) {
    state.artist = artist;
  },
  setSongs(state, songs) {
    state.songs = songs;
  },
  setAlbums(state, albums: Album[]) {
    state.albums = albums;
  },
 
};

const actions: ActionTree<ArtistState, RootState> = {
  async fetchArtist({ commit }, id: number) {
    const response = await axios.get(`https://wavesmusic.app/api/artists/${id}`);
    commit('setArtist', response.data);
  },
  async fetchSongsByArtist({ commit }, id: number) {
    const response = await axios.get(`https://wavesmusic.app/api/artists/${id}/songs`);

    const songs = response.data.map((song: ApiSong) => ({
      name: song.title,
      artist: song.artist,  
      src: song.audio_file_url,
      cover: song.album_cover_url,
      Id:song.Id
    }));

    commit('setSongs', songs);
  },
  async fetchCurrentSong({ commit }, id: number) {
    const response = await axios.get(`https://wavesmusic.app/api/songs/${id}`);
    commit('setCurrentSong', response.data);
    console.log("fetchCurrentSong: ", response.data)
  },
  async fetchAlbumsByArtist({ commit }, artistId: number) {
    const response = await axios.get(`https://wavesmusic.app/api/artists/${artistId}/albums`);
    const albums = response.data.map((album: Album) => ({
      id: album.id,
      name: album.title,
      cover: album.cover_image_url,
      albumSongs:album.Songs
    }));
    commit('setAlbums', albums);
  },

};

export default {
    
  state,
  mutations,
  actions,
};
