
import myCollectionNav from '@/components/myCollection/myCollectionNav.vue'
import myCollectionMyPlaylistsComponent from '@/components/myCollection/myPlaylists/myCollectionMyPlaylistsComponent.vue'

export default {
  components: {
 myCollectionNav,
 myCollectionMyPlaylistsComponent,
},
}

